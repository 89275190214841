import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Header } from "widgets/Header";
import {
  BacklogByPriorityReportWidget,
  BacklogByStatusReportWidget,
  MaintenanceHistoryByCostReportWidget,
  WorkOrderCompletionReportWidget,
  BacklogByAssigneeReportWidget,
  ReportListWidget,
  ReactiveVsPreventiveReportWidget,
  CreatedVsCompletedReportWidget,
  AssetCostsAndDowntimeReportWidget,
  WorkOrderCompletedByCategoryReportWidget,
  MaintenanceHistoryByAssigneeReportWidget,
  PartsInventoryReportWidget,
} from "modules/reporting";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { usePermission } from "app/providers/PermissionsProvider";
import { ReportPeriodSelect } from "shared/components/ReportPeriodSelect";
import { reportPeriodOptions } from "modules/reporting/lib/reportPeriodOptions";

export const ReportListPage = () => {
  const [reportPeriod, setReportPeriod] = useReportPeriod();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    enableWoByAssigneeReportPage,
    enableMaintenanceHistoryByAssigneeReportPage,
    enableWoCompletedByCategoryReportPage,
    enablePartsInventoryReports,
    enableAssetCostsDowntimeReport,
  } = useFlags();
  const {
    reportsAssigneePermit,
    reportsPriorityPermit,
    reportsStatusPermit,
    reportsCostPermit,
    reportsOverduePermit,
  } = usePermission();

  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <Header
        title={t("reporting.title")}
        action={
          <PeriodSelectWrapper>
            <ReportPeriodSelect
              selected={reportPeriod}
              onChange={setReportPeriod}
              options={reportPeriodOptions}
            />
          </PeriodSelectWrapper>
        }
      />
      <ReportList>
        {enablePartsInventoryReports && reportsAssigneePermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.PartsInventory}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.PartsInventory}`)
            }
          >
            <PartsInventoryReportWidget />
          </ReportListWidget>
        )}
        {enableWoCompletedByCategoryReportPage && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.CompletedByCategory}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.CompletedByCategory}`)
            }
          >
            <WorkOrderCompletedByCategoryReportWidget />
          </ReportListWidget>
        )}
        {enableWoByAssigneeReportPage && reportsAssigneePermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.BacklogByAssignee}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.BacklogByAssignee}`)
            }
          >
            <BacklogByAssigneeReportWidget />
          </ReportListWidget>
        )}
        <ReportListWidget
          label={t(`reporting.${ReportKey.CreatedVsCompleted}.title`)}
          onClickExpand={() =>
            navigate(`/reporting/${ReportKey.CreatedVsCompleted}`)
          }
        >
          <CreatedVsCompletedReportWidget />
        </ReportListWidget>
        {reportsPriorityPermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.BacklogByPriority}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.BacklogByPriority}`)
            }
          >
            <BacklogByPriorityReportWidget />
          </ReportListWidget>
        )}
        {reportsStatusPermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.BacklogByStatus}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.BacklogByStatus}`)
            }
          >
            <BacklogByStatusReportWidget />
          </ReportListWidget>
        )}
        <ReportListWidget
          label={t(`reporting.${ReportKey.ReactiveVsPreventive}.title`)}
          onClickExpand={() =>
            navigate(`/reporting/${ReportKey.ReactiveVsPreventive}`)
          }
        >
          <ReactiveVsPreventiveReportWidget />
        </ReportListWidget>
        {enableMaintenanceHistoryByAssigneeReportPage &&
          reportsAssigneePermit && (
            <ReportListWidget
              label={t(
                `reporting.${ReportKey.MaintenanceHistoryByAssignee}.title`
              )}
              onClickExpand={() =>
                navigate(`/reporting/${ReportKey.MaintenanceHistoryByAssignee}`)
              }
            >
              <MaintenanceHistoryByAssigneeReportWidget />
            </ReportListWidget>
          )}
        {enableAssetCostsDowntimeReport && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.AssetCostsAndDowntime}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.AssetCostsAndDowntime}`)
            }
          >
            <AssetCostsAndDowntimeReportWidget />
          </ReportListWidget>
        )}
        {reportsCostPermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.HistoryByCost}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.HistoryByCost}`)
            }
          >
            <MaintenanceHistoryByCostReportWidget />
          </ReportListWidget>
        )}
        {reportsOverduePermit && (
          <ReportListWidget
            label={t(`reporting.${ReportKey.OntimeVsOverdue}.title`)}
            onClickExpand={() =>
              navigate(`/reporting/${ReportKey.OntimeVsOverdue}`)
            }
          >
            <WorkOrderCompletionReportWidget />
          </ReportListWidget>
        )}
      </ReportList>
    </ReportPageProvider>
  );
};

const ReportList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  grid-auto-rows: 425px;
  padding: 16px 40px;

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const PeriodSelectWrapper = styled.div`
  width: 325px;
`;
