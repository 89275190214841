import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useGetFormFieldsQuery } from "modules/formFields";

import {
  addAssetValidationSchema,
  getFormatForService as getFormatForAssetService,
  useAddAssetMutation,
  initialValues,
  getOptionalFields,
} from "modules/assets";

import { AssetEditor } from "../AssetEditor";

import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { EditorModal } from "shared/components/Modals";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";

export const AssetCreate = ({
  isShow,
  defaultValues,
  onClose,
  onOpen,
  onCreate,
  defaultAssets,
  defaultOnAssetsSearch,
}) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const [generateQr, setGenerateQr] = useState(false);

  const [addAsset, addAssetRes] = useAddAssetMutation();

  const { data } = useGetFormFieldsQuery({
    type: "assets",
    action_type: "upsert",
  });

  const onCloseModal = () => {
    onClose();
    addAssetForm.resetForm();
    setGenerateQr(false);
  };

  const assetDataForForm = useMemo(
    () => ({ ...initialValues, ...defaultValues }),
    [defaultValues]
  );

  const optionalFields = getOptionalFields(data);

  const addAssetForm = useForm({
    initialValues: assetDataForForm,
    willUpdatedValues: assetDataForForm,
    validationSchema: addAssetValidationSchema(optionalFields, t, generateQr),
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const dataForApi = getFormatForAssetService(body, {
        generateQr,
        creation: true,
      });
      addAsset(dataForApi)
        .unwrap()
        .then(({ data }) => {
          const { id, name, location, vendors } = data;

          if (onCreate) {
            onCreate({
              value: id,
              label: name,
              location: location
                ? { value: location.id, label: location.name }
                : null,
              vendors,
            });
          } else {
            addSnackBar({
              title: t("assets.successCreationTitle"),
              content: t("assets.successCreationMessage", { name: data.name }),
              type: "success",
              primaryButtonLabel: t("assets.view"),
              secondaryButtonLabel: t("close"),
              handlePrimaryButtonClick: () => onOpen(data.id),
            });
          }
          window.totango.track("New Asset Created", "Assets");
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === RESPONSE_MESSAGE.already_taken) {
            setFieldError("name", t("m11", { ns: "errors" }));
          } else if (
            message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
          ) {
            setFieldError("qr_code_attributes", t("m2", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("assets.failedCreation"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: "Close",
            });
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("assets.createNew")}
      onClose={onCloseModal}
      onSubmit={addAssetForm.handleSubmit}
      disabledSubmit={
        !addAssetForm.dirty || !!Object.values(addAssetForm.errors).length
      }
      isSubmitLoading={addAssetRes.isLoading || addAssetRes.isSuccess}
    >
      <AssetEditor
        form={addAssetForm}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
        optionalFields={optionalFields}
        defaultAssets={defaultAssets}
        defaultOnAssetsSearch={defaultOnAssetsSearch}
      />
    </EditorModal>
  );
};

AssetCreate.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onCreate: PropTypes.func,
  defaultValues: PropTypes.any,
  defaultAssets: PropTypes.any,
  defaultOnAssetsSearch: PropTypes.any,
};
