/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect } from "react";

import {
  getInitialValues,
  getUserFormatForService,
  configureRoles,
} from "../../lib/helpers/form";
import {
  useInviteUserMutation,
  useLazyGetUsersRolesQuery,
} from "modules/users/state/usersApi";

import { useTranslation } from "react-i18next";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useLazyGetEntitiesQuery } from "modules/entities/state/entitiesApi";
import { inviteUserValidationSchema } from "../../config/validationSchema";

import { EditorModal } from "shared/components/Modals";
import { InvitedUserEditor } from "../UserEditors";
import { getUser } from "shared/components/Auth";

export const UserCreateInvite = ({ isShow, onClose, type = "user" }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [trigger, { currentData: entities }] = useLazyGetEntitiesQuery();
  const [triggerRoles, { currentData: userRoles }] =
    useLazyGetUsersRolesQuery();

  const user = getUser();
  const currentEntity =
    typeof user?.entity === "number" &&
    entities?.data?.find(({ id }) => id === user.entity);

  useEffect(() => {
    if (isShow) {
      trigger();
      triggerRoles();
    }
  }, [isShow, trigger, triggerRoles]);

  const [inviteUser, inviteUserRes] = useInviteUserMutation();

  const noAccessRoleId = userRoles?.data?.find(
    ({ name }) => name === "no_access"
  )?.id;

  const userDetailsDataForForm = useMemo(
    () =>
      getInitialValues({
        type: "create",
        entities,
        currentEntity: currentEntity?.name,
        noAccessRoleId,
        userType: type,
        t,
      }),
    [entities, userRoles]
  );

  const roleOptions = useMemo(
    () => configureRoles(userRoles, t),
    [userRoles, t]
  );

  const onCloseModal = () => {
    createUserInviteForm.resetForm();
    onClose();
  };

  const { setValues, ...createUserInviteForm } = useForm({
    initialValues: userDetailsDataForForm,
    validationSchema: inviteUserValidationSchema(t, noAccessRoleId),
    willUpdatedValues: userDetailsDataForForm,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const user = getUserFormatForService(body, entities);

      inviteUser({ body: user, entityUser: true })
        .unwrap()
        .then(({ data }) => {
          createUserInviteForm.resetForm();
          addSnackBar({
            content: t("allUsers.resendInvitation.success", {
              name: data.title,
            }),
            type: "success",
          });
          window.totango.track("New User Invited", "Users");
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.email?.[0] === "has already been taken") {
            setFieldError("email", t("m112", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("allUsers.snack.failSentInvite"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <EditorModal
      label={t("allUsers.inviteUser")}
      isOpen={isShow}
      secondaryButtonLabel={t("cancel")}
      onSubmit={createUserInviteForm.handleSubmit}
      primaryButtonLabel={t("allUsers.sendInvitation")}
      disabledSubmit={
        !createUserInviteForm.isWasChanged || !createUserInviteForm.isFormValid
      }
      onClose={onCloseModal}
      isSubmitLoading={inviteUserRes.isLoading || inviteUserRes.isSuccess}
    >
      <InvitedUserEditor
        form={createUserInviteForm}
        roleOptions={roleOptions}
      />
    </EditorModal>
  );
};
