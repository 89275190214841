import { ReportKey } from "modules/reporting/types/ReportKey";
import { CompletedByCategoryReportChart } from "./CompletedByCategoryReportChart";
import { CompletedByCategoryReportTable } from "./CompletedByCategoryReportTable";
import { useCompletedByCategoryKPIs } from "./useCompletedByCategoryKPIs";
import { CompletedByCategoryReportActionBar } from "./CompletedByCategoryReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { CompletedByCategoryKPIGrid } from "./CompletedByCategoryKPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  CompletedByCategoryChartData,
  CompletedByCategoryTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { useRef } from "react";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";
import { useReportPeriod } from "../../../lib/useReportPeriod";
import { ChartWithBucketOptionsWrapper } from "../../ReportBucketOptions";
import { usePersistentState } from "../../../lib/usePersistentState";
import { ReportBucket } from "../../../types/ReportBucket";

const reportKey = ReportKey.CompletedByCategory;
export const CompletedByCategoryReport = () => {
  const { t } = useTranslation();
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const kpiRes = useCompletedByCategoryKPIs();
  const tableRes = useGetTableData<CompletedByCategoryTableData[]>(
    reportKey,
    reportPeriod
  );
  const downloadXLS = useDownloadReportXLS(ReportKey.CompletedByCategory);
  const [bucket, setBucket] = usePersistentState<ReportBucket>(
    "report_bucket",
    "day"
  );
  const chartRes = useGetChartData<CompletedByCategoryChartData[]>(
    reportKey,
    reportPeriod,
    { bucket: bucket }
  );
  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={
          <CompletedByCategoryKPIGrid
            isLoading={kpiRes.isFetching}
            kpis={kpiRes.data.kpis}
            detailKpis={kpiRes.data.detailKpis}
          />
        }
        chart={
          <ChartWithBucketOptionsWrapper {...{ bucket, setBucket }}>
            <CompletedByCategoryReportChart
              bucket={bucket}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </ChartWithBucketOptionsWrapper>
        }
        actionBar={
          <CompletedByCategoryReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
          />
        }
        table={
          <CompletedByCategoryReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf")}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod: reportPeriodCurrent,
          kpis: kpiRes.data.kpis,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.title`) as string,
            chart: (
              <div style={{ width: "1000px", height: "600px" }}>
                <CompletedByCategoryReportChart
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                  bucket={bucket}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
