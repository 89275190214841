import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { CompletedByCategoryChartData } from "./types";
import { ChartColors } from "../../../../../shared/lib/colors";
import { ReportBucket } from "../../../types/ReportBucket";
import { bucketData } from "../../../lib/bucketData";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { BarChart } from "../../charts/BarChart";

interface CompletedByCategoryReportChartProps {
  data?: CompletedByCategoryChartData[];
  isFetching: boolean;
  bucket: ReportBucket;
}

const reportKey = ReportKey.CompletedByCategory;
const MAX_DISPLAYED_RECORDS = 8;
export const CompletedByCategoryReportChart = ({
  data,
  isFetching,
  bucket,
}: CompletedByCategoryReportChartProps) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const chartData = bucketData(data ?? [], bucket, language, (points) => {
    const base: CompletedByCategoryChartData = { date: "2012-10-02" };
    return points.reduce((bucket, p) => {
      bucket.date = p.date;
      Object.keys(p).forEach((key) => {
        if (key === "date") return;
        if (!bucket[key]) bucket[key] = 0;
        const val = p[key];
        if (!val) return;
        (bucket[key] as number) += parseInt(val.toString());
      });
      return bucket;
    }, base);
  });
  const createDisplayData = (chartData: CompletedByCategoryChartData[]) => {
    return chartData.map((row) => {
      const newRow: CompletedByCategoryChartData = { date: row["date"] };
      const sorted = Object.keys(row)
        .filter((x) => x !== "date" || row[x] !== undefined)
        .sort((a, b) => {
          let aData = row[a];
          let bData = row[b];
          if (aData === undefined) aData = 0;
          if (bData === undefined) bData = 0;

          return (bData as number) - (aData as number);
        });
      const found = sorted.splice(0, MAX_DISPLAYED_RECORDS);

      found.forEach((key) => {
        newRow[key] = row[key];
      });

      newRow["other"] = sorted.reduce((acc, curr) => {
        const val = row[curr];
        if (val === undefined) return acc;
        return acc + parseInt(val.toString());
      }, 0);
      return newRow;
    });
  };

  const removeEmptyEntries = (
    data: CompletedByCategoryChartData[]
  ): CompletedByCategoryChartData[] => {
    return data.map((row) => {
      // Remove rows with no data
      const newRow: CompletedByCategoryChartData = { date: row["date"] };
      Object.keys(row).forEach((key) => {
        // @ts-ignore: row[key] is not undefined
        if (row[key] !== undefined && row[key] > 0) {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });
  };

  let displayData = createDisplayData(chartData);
  displayData = removeEmptyEntries(displayData);
  const getBars = (displayData: CompletedByCategoryChartData[]) => {
    // Get all categories
    const uniqueCategories: Set<string> = new Set();
    displayData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "date") {
          uniqueCategories.add(key);
        }
      });
    });

    const categories = Array.from(uniqueCategories);
    return categories.map((category) => {
      return {
        dataKey: category,
        name: formatLabelsFromCategories(category),
        fill: ChartColors[categories.indexOf(category) % ChartColors.length],
        stackId: "1",
      };
    });
  };

  const formatLabelsFromCategories = (categories: string): string => {
    const lower = categories.toLowerCase();
    const splits = lower.split(";");
    const labels = splits.map((category) => {
      return t(`reporting.${reportKey}.labels.${category}`);
    });
    return labels.join("/");
  };

  const bars = displayData.length > 0 ? getBars(displayData) : [];
  return (
    <BarChart
      data={displayData}
      dataAxisKey={"date"}
      bars={bars}
      isLoading={isFetching}
    />
  );
};
