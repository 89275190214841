import { useTranslation } from "react-i18next";

import { useGetFormFieldsQuery } from "modules/formFields";

import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  getFormatForVendorService,
  getOptionalFields,
} from "../../lib/helpers";
import { useAddVendorMutation } from "modules/vendors/state/vendorsApi";
import { addVendorValidationSchema } from "../../configs/validationSchema";
import { initialValues } from "../../configs/constants";
import { VendorEditor } from "../VendorEditor";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";

export const VendorCreate = ({ isShow, onClose, onOpen }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [addVendor, addVendorRes] = useAddVendorMutation();

  const { data } = useGetFormFieldsQuery({
    type: "vendors",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(data);

  const onCloseModal = () => {
    addVendorForm.resetForm();
    onClose();
  };

  const addVendorForm = useForm({
    initialValues: initialValues,
    validationSchema: addVendorValidationSchema(optionalFields, t),
    willUpdatedValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const vendor = getFormatForVendorService(body);
      addVendor(vendor)
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("vendors.snack.successCreationTitle"),
            content: t("vendors.snack.successCreationMessage", {
              name: data.name,
            }),
            type: "success",
            primaryButtonLabel: t("vendors.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.id),
          });
          window.totango.track("New Vendor Created", "Vendors");
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("name", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("vendors.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("vendors.createNew")}
      onClose={onCloseModal}
      onSubmit={addVendorForm.handleSubmit}
      disabledSubmit={
        !addVendorForm.dirty || !!Object.values(addVendorForm.errors).length
      }
      isSubmitLoading={addVendorRes.isLoading || addVendorRes.isSuccess}
    >
      <VendorEditor form={addVendorForm} optionalFields={optionalFields} />
    </EditorModal>
  );
};
