import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getFilterTimeToCompleteOptions } from "modules/preventiveMaintenances/lib/helpers";
import { getOnMonthOptions } from "modules/preventiveMaintenances/lib/helpers/form";

import { DateTimePicker } from "shared/components/DatePicker";
import { formatDateWithDayOfWeek } from "shared/lib/helpers/common";
import { Dropdown } from "shared/components/Dropdowns";

import {
  weekDaysOptions,
  monthOptions,
  INITIAL_SCHEDULE_VALUES,
} from "../../configs/constants";
import { filterDate, getRepeatsText } from "../../lib/helpers/form";
import { convertTimeToCompleteHoursToDays } from "../../lib/helpers";

import { Repeats } from "./Repeats";
import { RepeatsOn } from "./RepeatsOn";

import {
  Label,
  InputContainer,
  TextBox,
  Box,
  Container,
  TypesContainer,
  TypeContainer,
  TextContainer,
  InlineLabel,
  InlineContainer,
} from "./ScheduleForm.styled";
import { getTimezoneDateFormats } from "shared/lib/helpers/index.js";
import { formatISO } from "date-fns";

const Types = ["one_time", "persistent", "floating"];

export const ScheduleForm = ({
  name,
  value = {},
  setValue,
  disabled = false,
  hasWorkOrders = false,
}) => {
  const { t } = useTranslation();
  const { dateTimePickerFormat } = getTimezoneDateFormats();

  const {
    start_date,
    type: scheduleType,
    time_to_complete,
    repeat_occurrence,
    repeat_frequency,
    on_week_days,
    in_months,
    on_month_day,
  } = value;

  const startDateValue = useMemo(
    () => (start_date ? new Date(start_date) : null),
    [start_date]
  );

  const timeToCompleteOptions = useMemo(() => {
    const initOptions = getFilterTimeToCompleteOptions(
      scheduleType,
      repeat_frequency,
      repeat_occurrence,
      on_week_days,
      in_months,
      time_to_complete
    );

    // In the case of a data import, it's possible the time_to_complete value
    // is not one of the standard options returned by getFilterTimeToCompleteOptions.
    if (!initOptions.map((option) => option.key).includes(time_to_complete)) {
      initOptions.push({
        key: time_to_complete,
        value: `${time_to_complete} hours`,
      });
    }

    return initOptions;
  }, [
    scheduleType,
    repeat_frequency,
    repeat_occurrence,
    on_week_days,
    in_months,
    time_to_complete,
  ]);

  const onMonthDayOptions = useMemo(() => getOnMonthOptions(), []);

  const handleTypeChange = (type) => {
    setValue(name, { ...INITIAL_SCHEDULE_VALUES, type });
  };

  return (
    <Box disabled={disabled}>
      <TypesContainer>
        {Types.map((type) => (
          <TypeContainer
            name="type"
            key={type}
            active={scheduleType === type}
            onClick={() => handleTypeChange(type)}
            disabled={disabled}
          >
            <span>{t(`work_orders.schedule_type.${type}`)}</span>
          </TypeContainer>
        ))}
      </TypesContainer>
      <Container>
        {scheduleType === "persistent" && (
          <>
            <Repeats
              name={name}
              scheduleType={scheduleType}
              occurrence={repeat_occurrence}
              frequency={repeat_frequency}
              setValue={setValue}
              disabled={disabled}
            />
            {repeat_frequency === "week" && (
              <RepeatsOn
                name="on_week_days"
                parentName={name}
                value={on_week_days}
                setValue={setValue}
                disabled={disabled}
                options={weekDaysOptions}
                label={t("work_orders.form.pm_schedule_attributes.repeat_on")}
                columns={7}
                rows={1}
              />
            )}
            {repeat_frequency === "month" && (
              <>
                {repeat_occurrence === "custom" && (
                  <RepeatsOn
                    name="in_months"
                    parentName={name}
                    value={in_months}
                    setValue={setValue}
                    disabled={disabled}
                    options={monthOptions}
                    columns={6}
                    rows={2}
                  />
                )}

                <InlineContainer>
                  <InlineLabel>
                    {t("work_orders.form.pm_schedule_attributes.on_month_day")}
                  </InlineLabel>
                  <Dropdown
                    name={`${name}.on_month_day`}
                    value={on_month_day}
                    options={onMonthDayOptions}
                    defaultOptions={[]}
                    setValue={setValue}
                    disabled={disabled}
                    withSearch={false}
                    withClear={false}
                  />
                </InlineContainer>
              </>
            )}
          </>
        )}
        {scheduleType === "floating" && (
          <Repeats
            name={name}
            scheduleType={scheduleType}
            occurrence={repeat_occurrence}
            frequency={repeat_frequency}
            setValue={setValue}
            disabled={disabled}
          />
        )}
        <InputContainer>
          <Label>
            {t("work_orders.form.pm_schedule_attributes.start_date")}
          </Label>
          <DateTimePicker
            name={`${name}.start_date`}
            selected={start_date}
            onChange={(val) => setValue(`${name}.start_date`, val)}
            dateFormat={dateTimePickerFormat}
            showIcon
            minDateTime={formatISO(new Date())}
            filterDate={(date) =>
              filterDate({
                date,
                repeat_frequency,
                on_week_days,
                repeat_occurrence,
                in_months,
                on_month_day,
                scheduleType,
              })
            }
            disabled={disabled}
          />
        </InputContainer>
        <InputContainer>
          <Label>
            {t("work_orders.form.pm_schedule_attributes.time_to_complete")}
          </Label>
          <Dropdown
            name={`${name}.time_to_complete`}
            value={time_to_complete}
            setValue={setValue}
            options={timeToCompleteOptions}
            defaultOptions={[]}
            withSearch={false}
            withClear={false}
            disabled={disabled}
          />
        </InputContainer>

        <TextContainer>
          {scheduleType === "one_time" ? (
            <TextBox>
              {startDateValue &&
                t(
                  "work_orders.form.pm_schedule_attributes.information.work_order",
                  {
                    value: formatDateWithDayOfWeek(startDateValue, true),
                  }
                )}
            </TextBox>
          ) : (
            <TextBox>
              {`${getRepeatsText({
                repeat_occurrence,
                repeat_frequency,
                on_month_day,
                on_week_days,
                in_months,
                scheduleType,
              })} ${
                startDateValue && !hasWorkOrders
                  ? t(
                      "work_orders.form.pm_schedule_attributes.information.creation",
                      {
                        startDate: formatDateWithDayOfWeek(
                          startDateValue,
                          true
                        ),
                      }
                    )
                  : ""
              }`}
            </TextBox>
          )}

          <TextBox>
            {t("work_orders.form.pm_schedule_attributes.information.due_date", {
              value: time_to_complete
                ? convertTimeToCompleteHoursToDays(time_to_complete)
                : "",
            })}
          </TextBox>
        </TextContainer>
      </Container>
    </Box>
  );
};
