const locales = {
  common: {
    apply: "Apply",
    delete: "Delete",
    details: "Details",
    discardChanges: "Discard Changes",
    download: "Download",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    searchPlaceholder: "Search",
    clear: "Clear",
    edit: "Edit",
    startTyping: "Start Typing",
    create: "Create",
    clone: "Clone",
    submit: "Submit",
    years_one: "year",
    years_other: "years",
    createOption: "+ Create {{name}}",
    noOptions: "No Options",
    updatedAt: "Last update on {{date}}",
    confirm: "Got it",
    view: "View",
    noResults: {
      title: {
        search: "No Results",
        filter: "No Matches Found",
      },
      description: {
        search: "Could not find results",
        filter: "Update filter settings",
      },
      preposition: "for",
    },
    sidebar: {
      assets: "Assets",
      entities: "Entities",
      requests: "Requests",
      workOrders: "Work Orders",
      pms: "PMs",
      locations: "Locations",
      parts: "Inventory & Parts",
      vendors: "Vendors",
      users: "Users",
      reporting: "Reports & KPIs",
      feedback: "Give Feedback",
      bugReport: "Report a Bug",
      settings: "Admin Settings",
    },
    empty: {
      descriptionUse: "Use ",
      descriptionButton: " button on the top to create one",
      parts: "[+ Part]",
      locations: "[+ Location]",
      assets: "[+ Asset]",
      vendors: "[+ Vendor]",
      workOrders: "[+ Work Order]",
      "part-types": "[+ Part Type]",
      "asset-types": "[+ Asset Type]",
      storerooms: "[+ Storeroom]",
      categories: "[+ Category]",
      requests: "[+ Request]",
      request_portals: "[+ Request Portal]",
    },

    table: {
      filters: "Filters",

      settings: {
        title: "View Settings",
        resultsPerPage: "Results per Page",
      },

      columns: {
        address: "Address",
        teams: "Teams in Charge",
      },
    },

    qrCodeInput: {
      label: "QR Code",
      generated: "Code will be generated automatically",
      enter: "Enter code",
      inputManually: "Input Manually",
      generateCode: "Generate Code",
    },

    action: {
      deleteSuccess: "{{module}} was successfully deleted",
      deleteFailed: "{{module}} removed failed",
      updateFailed: "{{module}} update failed",
    },

    files: {
      filePicker: {
        placeholder: "Upload <0>images</0> or <0>files</0> by drag and drop or",
        hover: "<0>Incoming! </0>Drop your files to start your upload.",
        upload: "click to upload",
      },
      image: {
        imageCount: "Image {{count}} of {{total}}",
        collapsed: "Show {{count}} more images",
        error: {
          upload: "Upload image failed",
        },
      },
      file: {
        collapsed: "Show {{count}} more files",
      },
    },

    privacyPolicy: {
      additionalText:
        "By Creating Account, {{person}} agree to the Click Maint",
      termsOfService: "Terms of Service",
      and: "and",
      privacyPolicy: "Privacy Policy",
    },

    assets: {
      title: "Assets",
      searchPlaceholder: "Search assets",
      noAssets: "No Assets Created Yet",
      asset: "Asset",
      asset_one: "Asset",
      asset_other: "Assets",
      family: "Asset Family",
      subItem: "Sub Asset",

      downtime_tracking: {
        changeStatus: "Change Status",
        manualStatus: "Manual Status",
        addManualStatus: "Add Manual Status",
        manualForm: {
          from: "From",
          to: "To",
        },
      },

      bulkActions: {
        setLocation: {
          hint: "Location",
          confirmation: {
            title: "Change Location",
            selected_one: "{{count}} Asset Selected",
            selected_other: "{{count}} Assets Selected",
            newLocation: "New Location",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success: "{{count}} assets were allocated to {{locationName}}",
        },
        delete: {
          confirmation: {
            title: "Delete assets?",
            description_one:
              "You're about to permanently delete {{count}} asset",
            description_other:
              "You're about to permanently delete {{count}} assets",
            confirmButtonLabel_one: "Delete asset",
            confirmButtonLabel_other: "Delete assets",
          },
          success: "{{count}} assets were successfully deleted",
          warningTitle: "Some of the assets cannot be deleted",
          warningContent:
            "Assets {{names}} are linked with at least one work order or request. Delete them first, then delete the assets",
          subWarning: {
            warningTitle: "Some of the sub assets cannot be deleted",
            warningContent:
              "Sub assets {{names}} are linked with at least one work order or request. Delete them first, then delete the sub assets",
          },
        },
        status: {
          hint: "Status",
          fields: {
            status: "Change Status",
            note: "Note",
            type: "Downtime Type",
          },
          placeholders: {
            status: "Select",
            note: "Note",
          },
          typeOptions: {
            unplanned: "Unplanned",
            planned: "Planned",
          },
          confirmation: {
            title: "Change Status (Beta)",
            selected_one: "{{count}} Asset Selected",
            selected_other: "{{count}} Assets Selected",
            new: "New Status",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success: "The status of {{count}} assets was updated to {{newValue}}",
          fail: "Failed to update status",
        },
      },

      statuses: {
        online: "Online",
        offline: "Offline",
        not_tracking: "Not tracking",
      },
      reliability: {
        title: "Reliability History",
        online: "Online",
        offline: "Offline",
      },

      tabs: {
        details: "Details",
        status: "Status (Beta)",
      },

      columns: {
        name: "Name",
        id: "ID",
        status: "Status (Beta)",
        parent: "Parent Asset",
        description: "Description",
        code: "QR Code",
        categories: "Category",
        assetTypes: "Asset Type",
        assignedTo: "Assigned to",
        manufacturer: "Manufacturer",
        model: "Model",
        serialNumber: "Serial No.",
        parts: "Parts",
        location: "Location",
        vendors: "Vendors",
        urls: "URL",
        purchaseCost: "Purchase Cost",
        purchaseDate: "Purchase Date",
        warrantyExpDate: "Warranty Expiration Date",
        warrantyExpirationNotification: "Warranty Expiration Notification",
        lifeExpectancy: "Life Expectancy",
        replacementCost: "Replacement Cost",
        salvageValue: "Salvage Value",
        createdBy: "Created by",
        createdWhen: "Created when",
        updatedBy: "Updated by",
        updatedWhen: "Updated when",
      },

      details: {
        location: "Location",
        assetType: "Asset type",
        model: "Model",
        manufacturer: "Manufacturer",
        serialNumber: "Serial No.",
        purchaseDate: "Purchase date",
        parent: "Parent",
        assignedTo: "Assigned to",
        urls: "URL",
        categories: "Category",
        warrantyExpirationDate: "Warranty Expiration Date",
        warrantyExpirationNotification: "Warranty Expiration Notification",
        lifeExpectancy: "Life Expectancy",
        purchaseCost: "Purchase Cost",
        replacementCost: "Replacement Cost",
        salvageValue: "Salvage Value",
        vendors: "Vendors",
        parts: "Parts",
        status: "Status",
      },

      fields: {
        labels: {
          description: "Description",
          location_id: "Location",
          asset_types_ids: "Asset Type",
          categories_ids: "Category",
          model: "Model",
          manufacturer: "Manufacturer",
          serial_number: "Serial No.",
          purchase_date: "Purchase Date",
          parent_id: "Parent Asset",
          assign_to: "Assign to",
          urls_attributes: "URL",
          vendors_ids: "Vendors",
          parts_ids: "Parts",
          warranty_exp_date: "Warranty Expiration Date",
          warranty_expiration_notification: "Warranty Expiration Notification",
          life_expectancy: "Life Expectancy",
          purchase_cost: "Purchase Cost",
          replacement_cost: "Replacement Cost",
          salvage_value: "Salvage Value",
          qr_code_attributes: "QR Code",
        },

        placeholders: {
          name: "Asset Name",
          description: "Description",
          model: "Model",
          manufacturer: "Manufacturer",
          serial_number: "Serial No.",
          purchase_date: "Purchase Date",
          warranty_exp_date: "Warranty Expiration Date",
          warranty_expiration_notification: "Warranty Expiration Notification",
          life_expectancy: "Expected life (number of months)",
          purchase_cost: "Purchase Cost",
          replacement_cost: "Replacement Cost",
          salvage_value: "Salvage Value",
          startTyping: "Start typing",
        },
      },
      notificationOptions: {
        day: "A day before expiration date",
        never: "Never",
        week: "A week before expiration date",
        month: "30 days before expiration date",
        threeMonth: "90 days before expiration date",
      },
      failedCreation: "Failed to create asset",
      createNew: "New Asset",
      edit: "Edit Asset #{{id}}",
      failedUpdate: "Failed to update asset",
      successCreationTitle: "Asset created",
      successCreationMessage: "{{name}} was successfully created",
      view: "View asset",
      deletionConfirmation: {
        title: "Delete asset?",
        description: "You're about to permanently delete the asset",
        confirm: "Delete asset",
      },
      subDeletionConfirmation: {
        title: "Delete sub assets?",
        description:
          "This asset has at least one sub asset. Do you want to delete sub assets along with this asset?",
        confirm: "Delete sub assets",
        cancel: "Delete only this asset",
      },
      successDeletion: "{{name}} was successfully deleted",
      warningDeletionTitle: "Asset cannot be deleted",
      warningDeletionContent:
        "The asset is linked with at least one work order or request. Delete them first, then delete the asset",
      clone: {
        title: "Clone Asset",
        itemNumberTitle: "Number of Assets to be Cloned",
        description: "Mark fields that have to be cloned into new assets:",
        successTitle: "Asset cloned",
        successMessage: "Asset was successfully cloned",
        settingsLabels: {
          location: "Location",
          asset_types: "Asset Type",
          categories: "Category",
          model: "Model",
          manufacturer: "Manufacturer",
          serial_number: "Serial No.",
          purchase_date: "Purchase Date",
          qr_code: "QR Code",
          parent: "Parent Asset",
          assign_to: "Assign to",
          warranty_exp_date: "Warranty Expiration Date",
          warranty_expiration_notification: "Warranty Expiration Notification",
          vendors: "Vendors",
          parts: "Parts",
          urls: "URL",
          life_expectancy: "Life Expectancy",
          purchase_cost: "Purchase Cost",
          replacement_cost: "Replacement Cost",
          salvage_value: "Salvage Value",
          images_and_files: "Images and files",
          description: "Description",
        },
      },
    },

    locations: {
      title: "Locations",
      searchPlaceholder: "Search locations",
      title_one: "Location",
      title_other: "Locations",
      family: "Location Family",
      subItem: "Sub Location",

      noLocations: "No Locations Created Yet",
      name: "Location | {{name}}",
      new: "New Location",

      form: {
        error: {
          create: "Failed to create location",
        },
      },
      deletionConfirmation: {
        title: "Delete location?",
        description: "You're about to permanently delete the location",
        confirm: "Delete location",
      },
      subDeletionConfirmation: {
        title: "Delete sub location?",
        description:
          "This location has at least one sub location. Do you want to delete sub locations along with this location?",
        confirm: "Delete sub locations",
        cancel: "Delete only this location",
      },
      bulkActions: {
        delete: {
          confirmation: {
            title: "Delete locations?",
            description_one:
              "You're about to permanently delete {{count}} location",
            description_other:
              "You're about to permanently delete {{count}} locations",
            confirmButtonLabel_one: "Delete location",
            confirmButtonLabel_other: "Delete locations",
          },
          success: "{{count}} locations were successfully deleted",
          warningTitle: "Some of the locations cannot be deleted",
          warningContent:
            "Locations {{names}} are linked with at least one asset or work order or request. Delete them first, then delete the locations",
          subWarning: {
            warningTitle: "Some of the sub locations cannot be deleted",
            warningContent:
              "Sub locations {{names}} are linked with at least one asset or work order or request. Delete them first, then delete the sub locations",
          },
        },
      },
      warningDeletionTitle: "Location cannot be deleted",
      warningDeletionContent:
        "The location is linked with at least one asset or work order or request. Delete them first, then delete the location",
      parentLocation: "Parent Location",

      columns: {
        name: "Name",
        parent: "Parent Location",
        description: "Description",
        address: "Address",
        path: "Path",
      },
    },

    workOrder: {
      title: "Work Order",
    },

    validation: {
      required: "Required field",
      positive: "Only positive numeric values",
      positiveOrZero: "Must be positive number or zero",
      number: "Must be a number",
      email: "Invalid email",
      integer: "Must be an integer",
      stringMaxLength: "Must be less than {{stringMaxLength}} characters",
      maxNumber: "Must be less or equal to {{limit}}",
      fractionalNumberMaxLength:
        "Max number of digits for integer part -  {{integerLength}}, for decimal part -  {{decimalLength}}",
      onlyPositiveNumber: "Only positive numeric values",
      enterEmail: "Please enter a valid email address",
      incorrectFormat: "Incorrect format",
      url: "Invalid URL",
      uniqueness: "Must be unique",
    },

    defaultEntity: {
      label: "Entities",
      text: "Create a new entity when you want to create аn additional isolated operation (Ex: production facility). By default, users from one entity are restricted from accessing items and data from other entities. The default entity is already created. You can change its settings now or later in company settings.",
      submit: "Let’s Start",
    },

    generalSettings: {
      entities: "Entities",
      addEntity: "Add Entity",
      editEntity: "Edit Entity",
      customer: {
        editButton: "Edit Profile",
        modalLabel: "Edit Name",
        companyName: "Company Name",
      },
      entity: {
        error: "Could not retrieve entities",
        name: "Entity Name",
        namePlaceholder: "Entity Name",
        description: "Description",
        descriptionPlaceholder: "Description",
        address: "Address",
        addressPlaceholder: "Address",
        dateFormat: "Date Format",
        currency: "Currency",
        timeZone: "Timezone",
        defaultCurrency: "USD (United States Dollar)",
        confirmation: {
          title: "Change currency format?",
          description:
            "Changing currency will update all previous records with the new currency. Do you still want to change?",
          confirm: "Change currency",
        },
        deleteConfirmation: {
          title: "Delete entity?",
          description: "You're about to permanently delete the entity",
          confirm: "Delete entity",
        },

        deleteSnackBar: {
          success: "{{entity}} was successfully deleted",
          warning: {
            title: "Entity cannot be deleted",
            content:
              "The entity is linked with at least one location, asset, vendor, part, request, work order, PM, or user. Delete them first, then delete the entity",
            close: "Close",
          },
        },
      },
    },

    notificationSettings: {
      created_by_me: "Created by Me",
      assigned_to_me: "Assigned to Me",
      sent_for_review: "Sent for Review",
      reports: "Reports",
      fields: {
        is_notify_creator_new_comment_on_wo: "All New Comments",
        is_notify_creator_wo_overdue: "Becomes Overdue",
        is_notify_creator_wo_status_changed: "Status has Changed",
        is_notify_creator_new_preventive_wo: "Preventive Work Orders",
        is_notify_assignee_new_wo: "New Work Orders",
        is_notify_assignee_new_comment_on_wo: "All New Comments",
        is_notify_assignee_wo_overdue: "Becomes Overdue",
        is_notify_assignee_wo_status_changed: "Status has Changed",
        is_notify_wo_for_review: "Sent for Review",
        is_notify_new_wo_request: "New Work Order Requests",
        is_notify_asset_status_offline: "Asset Status is Offline",
        is_notify_asset_warranty_exp: "Warranty Expiration Notifications",
        is_notify_part_hits_min: "Stock Part Hits Minimum Threshold",
        is_notify_daily_report: "Daily Reports",
        is_notify_weekly_report: "Weekly Reports",
      },
    },

    allUsers: {
      viewPermissions: "View Permissions",
      error: "Could not retrieve users",
      noInvitedUsers: "No Users Invited Yet",
      title: "All Users",
      searchPlaceholder: "Search users",
      inviteUser: "Invite User",
      sendInvitation: "Send Invitation",
      editProfile: "Edit Profile",
      addNewUser: "User",

      columns: {
        title: "Title",
        status: "Profile",
        email: "Email",
        last_visit: "Last visit",
        logLast90Days: "Log Ins, Last 90 Days",
      },

      users: "Users",
      teams: "Teams",
      loading: "Loading...",

      make: {
        active: "Make Active",
        inactive: "Make Inactive",
        removeAccess: "Remove Access to This Entity",
      },

      roles: {
        limited_admin: "Limited Admin",
        admin: "Admin",
        technician: "Technician",
        requester: "Requester",
        no_access: "No Access",
      },

      status: {
        active: "Active",
        inactive: "Inactive",
        invitation_sent: "Invitation Sent",
        verification_pending: "Verification pending",
        verification_expired: "Verification expired",
        invitation_not_sent: "Invitation Not Sent",
        invitation_expired: "Invitation Expired",
        change_email_in_progress: "Changing email",
      },

      info: {
        status: "Profile",
        phone: "Phone",
        email: "Email",
        can_be_assigned_to_wo: "Work Orders",
        can_be_assigned_to_wo_true: "Can be Assigned",
        can_be_assigned_to_wo_false: "Cannot be Assigned",
        regular_rate: "Regular rate",
        overtime_rate1: "Overtime 1",
        overtime_rate2: "Overtime 2",
        entities: "Entities",
        last_visit: "Last visit",
      },

      cancelInvitation: {
        buttonTitle: "Cancel Invitation",
        title: "Cancel invitation?",
        description:
          "You're about to cancel the invitation and permanently delete the invited user.",
        confirm: "Delete Invitation",
        cancel: "Cancel",
        success: "The invitation is successfully cancelled",
        fail: "An error occurred. Please try again later",
      },

      resendInvitation: {
        buttonTitle: "Resend Invitation",
        success: "The invitation is successfully sent",
        fail: "An error occurred while attempting to send the email invitation. Please try resending it later",
      },

      removeAccessToEntityConfirmation: {
        title: "Remove access to this entity?",
        description: "We'll revoke the user access to {{entity}} data",
        confirm: "Remove access",
        success: "{{fullName}} no longer has access to the {{entity}} data ",
      },

      changeStatusToActiveConfirmation: {
        title: "Make user active?",
        description: "We'll grant the user access to the app.",
        confirm: "Make active",
        success:
          "{{fullName}} is active again and has access to the app according to their role permissions.",
      },

      changeStatusToInactiveConfirmation: {
        title: "Make user inactive?",
        description: "We'll revoke the user access to the app.",
        confirm: "Make inactive",
        success: "{{fullName}} is inactive now and has no access to the app ",
      },

      snack: {
        successUpdateMessage: "{{name}} was successfully updated",
        failUpdateTitle: "Part Was Not Updated",
        failSentInvite:
          "An error occurred while attempting to send the email invitation. Please try resending it later",
        view: "View Part",
      },

      form: {
        name: "First Name",
        surname: "Last Name",
        job_title: "Job Title",
        phone_number: "Phone",
        email: "Email",
        startTyping: "Start typing",
        canAssignToWorkOrder: "Can Be Assigned to Work Orders",
        ratePlaceholder: "Rate",
        regular_rate: "Regular rate",
        overtime_rate1: "Overtime 1",
        overtime_rate2: "Overtime 2",
        blocks: {
          entities: "Entities",
          assignToWorkOrder: "Assign to Work Orders and Labor Rates",
          assignWorkOrderTooltip:
            "Assigning work orders is primarily used for Technicians who are completing the work required. It allows the user’s labor time and cost to be tracked.",
        },
      },

      validation: {
        email: "Please enter a valid email address",
        permissions: "User must have access to at least one entity",
      },

      detailsError: "Error User details",
      profile: "Profile | {{name}}",
      invitation: "Invitation | {{email}}",
      generalInfo: "General Info",
      history: "History",
    },

    settings: {
      title: "Settings",
      referenceTitle: "Reference Lists",
      subtitle: "Available Fields",
      subdescription: "Mark fields that have to be applied to {{module}}",
      tabs: {
        generalSettings: "General Settings",
        integrations: "Integrations",
        manageModules: "Manage Modules",
        allUsers: "All Users",
        importData: "Import Data",
      },

      manageModules: {
        assets:
          "Configure the Asset Form. Select which fields should be visible and required.",
        inventoryParts:
          "Configure the Part Form. Select which fields should be visible and required.",
        vendors:
          "Configure the Vendor Form. Select which fields should be visible and required.",
        work_orders:
          "Configure the Work Order Form. Select which fields should be visible and required.",
        requests:
          "Configure the Work Order Request Form. Select which fields should be visible and required.",
        request_portals:
          "Create and configure Request Portals using a secure link.",
        locations:
          "Configure the Location Form. Select which fields should be visible and required.",
      },

      referenceLists: {
        "part-types": {
          title: "Part Types",
          add: "Part Type",
          columnName: "Part Type",
          modalLabelAdd: "Add Part Type",
          modalLabelEdit: "Edit Part Type",
          description:
            "Manage Part Types to classify Inventory & Parts within the organization.",
          noReference: "No Part Types Сreated Yet",
          modal: {
            name: "Part Type Name",
            namePlaceholder: "Part Type Name",
            entities: "Entities",
            alreadyExist: "This part type name already exists",
          },
          snack: {
            successCreatedMessage: "{{name}} was successfully created",
            deleteSuccessMessage: "{{name}} was successfully deleted",
            failDeleteTitle: "Part type cannot be deleted",
            failDeleteContent:
              "The part type is linked with at least one part. Delete them from the details of those parts first, then delete the part type",
          },
          deleteConfirmation: {
            title: "Delete part type?",
            description: "You're about to permanently delete the part type",
            confirm: "Delete part type ",
          },
        },
        categories: {
          title: "Categories",
          add: "Category",
          columnName: "Category",
          modalLabelAdd: "Add Category",
          modalLabelEdit: "Edit Category",
          description: "Manage Categories to classify Work Orders.",
          noReference: "No Categories Сreated Yet",
          modal: {
            name: "Category Name",
            namePlaceholder: "Category Name",
            entities: "Entities",
            alreadyExist: "This category name already exists",
          },
          snack: {
            successCreatedMessage: "{{name}} was successfully created",
            deleteSuccessMessage: "{{name}} was successfully deleted",
            failDeleteTitle: "Category cannot be deleted",
            failDeleteContent:
              "The category is linked with at least one asset, vendor, request, work order or PM. Delete them from the details of those items first, then delete the category",
          },
          deleteConfirmation: {
            title: "Delete category?",
            description: "You're about to permanently delete the category",
            confirm: "Delete category",
          },
        },

        storerooms: {
          title: "Storerooms",
          add: "Storeroom",
          columnName: "Storeroom",
          modalLabelAdd: "Add Storeroom",
          modalLabelEdit: "Edit Storeroom",
          description:
            " Manage Storerooms to organize Inventory & Parts storage location within the organization.",
          noReference: "No Storerooms Сreated Yet",
          modal: {
            name: "Storeroom Name",
            namePlaceholder: "Storeroom Name",
            entities: "Entities",
            alreadyExist: "This storeroom name already exists",
          },
          snack: {
            successCreatedMessage: "{{name}} was successfully created",
            deleteSuccessMessage: "{{name}} was successfully deleted",
            failDeleteTitle: "Storeroom cannot be deleted",
            failDeleteContent:
              "The storeroom is linked with at least one part. Delete them from the details of those parts first, then delete the storeroom",
          },
          deleteConfirmation: {
            title: "Delete storeroom?",
            description: "You're about to permanently delete the storeroom",
            confirm: "Delete storeroom",
          },
        },

        "asset-types": {
          title: "Asset Types",
          add: "Asset Type",
          columnName: "Asset Type",
          modalLabelAdd: "Add Asset Type",
          modalLabelEdit: "Edit Asset Type",
          description:
            "Manage Asset Types to classify Assets within the organization.",
          noReference: "No Asset Types Сreated Yet",
          modal: {
            name: "Asset Type Name",
            namePlaceholder: "Asset Type Name",
            entities: "Entities",
            alreadyExist: "This asset type name already exists",
          },
          snack: {
            successCreatedMessage: "{{name}} was successfully created",
            deleteSuccessMessage: "{{name}} was successfully deleted",
            failDeleteTitle: "Asset type cannot be deleted",
            failDeleteContent:
              "The asset type is linked with at least one asset. Delete them from the details of those assets first, then delete the asset type",
          },
          deleteConfirmation: {
            title: "Delete asset type?",
            description: "You're about to permanently delete the asset type",
            confirm: "Delete asset type",
          },
        },
      },

      saved: "Settings was successfully saved",
      failed: "Failed to save settings",
      field: "Field",
      visible: "Visible",
      required: "Required",
      labels: {
        vendor: {
          rating: "Rating",
          vendor_contacts: "Contacts",
          tax_id: "Tax ID",
          urls: "URL",
          address: "Address",
          locations: "Location",
          hourly_rate: "Hourly Rate",
          categories: "Category",
          assets: "Assets",
          parts: "Parts",
        },
        location: {
          parent: "Parent Location",
          description: "Description",
          address: "Address",
          qr_code: "QR Code",
          path: "Path",
        },
        part: {
          unit_cost: "Unit Cost",
          number: "Part Number",
          part_type: "Part Type",
          assets: "Assets",
          vendors: "Vendors",
          lead_time: "Lead Time (Days)",
          urls: "URL",
        },
        asset: {
          location: "Location",
          asset_types: "Asset Type",
          categories: "Category",
          model: "Model",
          manufacturer: "Manufacturer",
          serial_number: "Serial No.",
          purchase_date: "Purchase Date",
          qr_code: "QR Code",
          parent: "Parent Asset",
          assign_to: "Assigned to",
          warranty_exp_date: "Warranty Expiration Date",
          vendors: "Vendors",
          parts: "Parts",
          urls: "URL",
          life_expectancy: "Life Expectancy",
          purchase_cost: "Purchase Cost",
          replacement_cost: "Replacement Cost",
          salvage_value: "Salvage Value",
        },
        work_order: {
          description: "Description",
          priority: "Priority",
          categories: "Category",
          assign_to: "Assigned to",
          due_date: "Due Date",
          location: "Location",
          asset: "Asset",
          vendors: "Vendor",
          checklist: "Checklist",
        },
        work_order_request: {
          description: "Description",
          priority: "Priority",
          categories: "Category",
          assign_to: "Assigned to",
          vendors: "Vendors",
          due_date: "Needed by",
          location: "Location",
          asset: "Asset",
        },
        work_order_status: {
          title: "Statuses",
          label: "Enable additional status “In review”",
          description:
            "Require work orders to be reviewed by Admin User before work orders can be Completed.",
        },
        track_downtime: {
          title: "Track Downtime (Beta)",
          label: "Enables asset downtime tracking",
        },
      },
    },

    permissions: {
      viewPermissionsTitle: "User Roles & Permissions",
      list: {
        admin: {
          title: "Admin",
          description:
            "Have complete control over your Click Maint account. They hold full privileges, enabling them to add, change, or remove anything in the system. They are the ultimate controllers of the account.",
          option0:
            "Company creator by default is assigned the role of Full Admin",
          option1:
            "Can manage company settings, create, edit, and delete Entities within the company",
          option2:
            "Can customize and configure Assets, Vendors, Inventory & Parts, Work Order Requests, Work Order forms within the company",
          option3:
            "Can invite new users to the company, view and manage users’ profile info, make the users active/inactive",
          option4:
            "Have access to all the sections within all the Entities of the company and can create, edit, and delete Locations, Assets, Parts, Vendors, Work Order Requests, Work Orders, PMs, view Reports & KPIs",
          option5:
            "Can approve Work Order Requests within the all Entities of the company",
          option6:
            "Can reopen and edit Work Orders that have been Completed within all Entities ",
        },
        limitedAdmin: {
          title: "Limited Admin",
          description:
            "Similar to Full Admin users however they are limited to specific entities or sites. They can add, change, or remove anything in the specific entities they are assigned to.",
          option0:
            "Operate within the Entities the Full Admin granted them access to",
          option1:
            "Do not have access to Admin settings: they cannot manage company settings, create, edit, and delete Entities, manage forms and reference lists within the company",
          option2:
            "Can invite new users and revoke users’ access to the Entities they have access to",
          option3:
            "Can create, edit, and delete Locations, Assets, Parts, Vendors, Work Order Requests, Work Orders, PMs, view Reports & KPIs within the Entities they have access to",
          option4:
            "Can approve Work Order Requests within the Entities they have access to",
          option5:
            "Can reopen and edit Work Orders that have been Completed within the Entities they have access to",
        },
        technician: {
          title: "Technician",
          description:
            "Are assigned to work orders. They have permissions to create work orders and update work orders they are assigned to.",
          option0: "Can be assigned to Work Orders and PMs",
          option1:
            "Can create Work Order Requests, edit and delete Work Order Requests created by them in “Pending” status ",
          option2:
            "Can create Work Orders and PMs, edit and delete Work Orders and PMs created by them",
          option3:
            "Can add Parts and Other Costs to Work Orders assigned to them or created by them",
          option4: "Can add Time to the Work Orders assigned to them",
          option5:
            "Can view Reports and KPIs for Maintenance Backlog by Priority, Maintenance Backlog by Status, WO Completed On-time vs. Overdue Reports",
          option6: "Cannot view or access Labor Cost data",
          option7:
            "Cannot reopen and edit Work Orders that have been Completed",
        },
        requester: {
          title: "Requester",
          description:
            "Are users that are limited to submitting maintenance requests. They can submit requests for maintenance and get updates on the progress.",
          option0:
            "Can only create Work Order Requests, edit and delete Work Order Requests created by them in “Pending” status and receive notifications about the progress",
          option1: "Cannot be assigned to Work Orders and PMs",
          option2:
            "Cannot view Locations, Assets, Parts, Vendors, Users, Work Order Requests, Work Orders, PMs, Reports & KPIs",
        },
      },
    },

    filters: {
      title: "Filters",
      where: "Where",
      and: "And",
      buttons: {
        clearAll: "Clear all",
        addCondition: "Add condition",
        pickOption: "Pick up a property to filter",
      },
      desc: {
        assets: "In this view, show assets",
        locations: "In this view, show locations",
        parts: "In this view, show parts",
        vendors: "In this view, show vendors",
        work_orders: "In this view, show work orders",
        work_order_requests: "In this view, show work order requests",
        users: "In this view, show users requests",
        preventive_maintenances: "In this view, show preventive maintenances",
      },
      quick: {
        asset: "Displayed Sub Assets:",
        location: "Displayed Sub Locations:",
      },
      toggles: {
        overdue: "Overdue & Due Today",
        currentUser: "Assigned to Me",
      },
    },

    parts: {
      title: "Inventory & Parts",
      searchPlaceholder: "Search inventory",
      addButton: "Part",
      noParts: "No Parts Created Yet",
      error: "Could not retrieve parts",
      name: "Part | {{name}}",
      edit: "Edit Part #{{id}}",
      select: "Part",
      select_one: "Part",
      select_other: "Parts",
      columns: {
        name: "Name",
        stockLevel: "Stock Level",
        partType: "Part Type",
        number: "Number",
        onHand: "On Hand",
        available: "Available",
        minimum: "Minimum",
        desired: "Desired",
        unitCost: "Unit Cost",
        unitMeasurement: "Unit Measurement",
        area: "Area / Bin",
        vendors: "Vendors",
        assets: "Assets",
        id: "ID",
        description: "Description",
        storeroom: "Storeroom",
        leadTime: "Lead Time (Days)",
        code: "QR Code",
        urls: "URLs",
        createdAt: "Created when",
        updatedAt: "Updated when",
        createdBy: "Created by",
        updatedBy: "Updated by",
        transactions: "Transaction",
        qty: "Qty",
        details: "Details",
        // note: "Notes",
      },
      createNew: "New Part",
      costButton: {
        update: "Update cost",
        restock: "Restock",
      },
      costTitle: {
        update: "Update Part Cost",
        restock: "Restock Part",
      },
      stock: {
        onHand: "On Hand",
        cost: "Unit Cost",
        nonStock: "Non-Stock Part",
        available: "Available",
        reserved: "Reserved",
      },

      transactions: {
        added: "Added",
        created: "Created",
        reserved: "Reserved",
        used: "Used",
        reversal: "Reversal",
        planned: "Planned",
      },

      transaction: {
        title: "Transaction Details RSTK #{{id}}",
        created_at: "Date",
        quantity: "Quantity",
        cost: "Unit Cost",
        note: "Notes",
        partsUsage: "Used for All Time",
        editTransaction: "Edit Transaction",
      },

      form: {
        name: "Name",
        description: "Description",
        nonStock: "Non Stock Part",
        onHand: "On Hand",
        startTyping: "Start Typing",
        minimum: "Minimum",
        desired: "Desired",
        unitCost: "Unit Cost",
        partNumber: "Part Number",
        vendorPartNumber: "Vendor's Part Number",
        vendorPlaceholder: "Vendor",
        addVendor: "Add Vendor",
        preferred: "Preferred",
        partType: "Part Type",
        storeroom: "Storeroom",
        area: "Area / Bin",
        assets: "Assets",
        leadTime: "Lead Time (Days)",
        leadTimeUnit: "Days",
        url: "URL",
        qrCode: "QR Code",
        note: "Notes",
        quantity: "Adjustment",
        adjust: {
          onHand: "On Hand: {{onHand}}",
          newQty: "New Qty.: {{newQty}}",
        },
        vendors: "Vendors",
        measurementUnit: "Unit of Measure",
        restockCost: "Unit Cost",
      },
      snack: {
        successCreationTitle: "Part Created Successfully",
        successCreationMessage: "{{name}} was successfully created",
        successUpdateTitle: "Part Updated Successfully",
        successUpdateMessage: "{{name}} was successfully updated",
        failCreationTitle: "Part Was Not Created",
        failUpdateTitle: "Part Was Not Updated",
        view: "View Part",
      },
      details: {
        min_qty: "Minimum",
        part_type: "Part Type",
        storeroom: "Storeroom",
        assets: "Assets",
        lead_time: "Lead Time (Days)",
        vendors: "Vendors",
        area: "Area / Bin",
        urls: "URL",
        number: "Part Number",
        preferred: "Preferred",
        phone: "Tel:",
      },

      unitCostDescription:
        "Unit cost here is taken from the initial part setup or the most recent 'Restock' transaction. It's for your information only. For the purpose of defining part value when it's used, the application calculates the weighted average cost based on the part transactions history",
      unitCostNonStockDescription:
        "Unit cost here is taken from the initial part setup or the most recent 'Cost Updated' transaction",

      bulkActions: {
        delete: {
          confirmation: {
            title: "Delete parts?",
            description_one:
              "You're about to permanently delete {{count}} parts",
            description_other:
              "You're about to permanently delete {{count}} parts",
            confirmButtonLabel_one: "Delete part",
            confirmButtonLabel_other: "Delete parts",
          },
          success: "{{count}} parts were successfully deleted",
          warningTitle: "Some of the parts cannot be deleted",
          warningContent:
            "Parts {{names}} are linked with at least one work order. Delete them first, then delete the parts",
        },
      },
      deletionConfirmation: {
        title: "Delete part?",
        description: "You're about to permanently delete the part",
        confirm: "Delete part",
      },
      warningDeletionTitle: "Part cannot be deleted",
      warningDeletionContent:
        "The part is linked with at least one work order. Delete them first, then delete the part",
      clone: {
        title: "Clone Part",
        itemNumberTitle: "Number of Parts to be Cloned",
        description: "Mark fields that have to be cloned into new parts:",
        successTitle: "Part cloned",
        successMessage: "Part was successfully cloned",
        settingsLabels: {
          images_and_files: "Images and files",
          description: "Description",
          urls: "URL",
          assets: "Assets",
          number: "Part Number",
          vendors: "Vendors",
          lead_time: "Lead Time (Days)",
          part_type: "Part Type",
          unit_cost: "Unit Cost",
          is_non_stock: "Non-Stock Part",
          on_hand_qty: "On Hand",
          min_qty: "Minimum",
          desired_qty: "Desired",
          measurement_unit: "Unit Of Measure",
          storeroom: "Storeroom",
          area: "Area / Bin",
        },
      },
    },

    work_order_requests: {
      pageTitle: "Requests",
      searchPlaceholder: "Search requests",
      createNew: "New Request",
      edit: "Edit Request #{{id}}",
      form: {
        workOrder: "New work order will be created using data below:",
        comments: "Add comments",
        title: "Title",
        description: "Description",
        notify: "Receive Notifications on Updates",
        priority: "Priority",
        location: "Location",
        startTyping: "Start typing",
        assets: "Asset",
        category: "Category",
        due_date: "Needed by",
        datePlaceholder: "mm/dd/yyyy",
        vendor: "Vendor",
        assign_to: "Assign to",
        set_asset_to_offline: "Set Asset Status to “Offline” (Beta)",
        noCategories: "No Categories Found",
        depended: {
          locations: {
            selected: "Selected Location",
            asset: "Location Connected With Asset",
            other: "Other Locations",
            vendors: "Locations Connected With Vendors",
            noOptions: "No Connected Locations",
          },
          assets: {
            selected: "Selected Asset",
            other: "Other Assets",
            location: "Assets Connected With Location",
            empty: "Assets Without Location",
            vendors: "Assets Connected With Vendors",
            noOptions: "No Connected Assets",
            noVendors: "Assets Without Vendors",
          },
          vendors: {
            selected: "Selected Vendors",
            asset: "Vendors Connected With Asset",
            location: "Vendors Connected With Location",
            other: "Other Vendors",
            noOptions: "No Connected Vendors",
          },
        },
      },
      approve: "Approve Request | New Work Order",
      approveTitle: "New work order will be created using data below:",
      bulkActions: {
        updateStatus: {
          title: {
            on_hold: "Put Requests On Hold",
            declined: "Decline Requests",
          },
          confirmation: {
            on_hold: {
              selected_one: "{{count}} Pending Request Selected",
              selected_many: "{{count}} Pending Requests Selected",
            },
            declined: {
              selected_one: "{{count}} Pending or On-Hold Request Selected",
              selected_many: "{{count}} Pending or On-Hold Requests Selected",
            },
          },
          snack: {
            success: {
              on_hold: "Requests were put on hold successfully",
              declined: "Requests were declined successfully",
            },
            fail: "Failed to update status",
            noSelected: {
              on_hold:
                "None of the selected requests is “Pending”. You can put on hold only “Pending” requests. Choose them and repeat the action",
              declined:
                "None of the selected requests are “Pending” or “On Hold”. You can only decline “Pending” or “On Hold” requests. Choose them and repeat the action",
            },
          },
        },
      },
      labels: {
        notifications: "Notifications",
        status: "Status",
        priority: "Priority",
        vendors: "Vendors",
        categories: "Category",
        due_date: "Needed by",
        location: "Location",
        asset: "Asset",
        assign: "Assigned to",
        createdBy: "Requested by",
        anonymous: "No Contact Information Provided",
      },
      details: {
        notify: {
          false: "Off",
          true: "On",
        },
      },
      snack: {
        successCreationTitle: "Request Created Successfully",
        successCreationMessage: "{{name}} was successfully created",
        successUpdateTitle: "Request Updated Successfully",
        successUpdateMessage: "{{name}} was successfully updated",
        failCreationTitle: "Request Was Not Created",
        failUpdateTitle: "Request Was Not Updated",
        view: "View Request",
      },
      header: {
        approved: "Approve Request",
        declined: "Decline Request",
        on_hold: "Put Request On Hold",
        work_order: "Work Order",
      },
      name: "Request | {{name}}",
      title: "Work Order Requests",
      addButton: "Request",
      empty: "No Requests Created Yet",
      error: "Could Not Retrieve Requests",
      settingsTitle: "Work Order Requests",
      status: {
        pending: "Pending",
        approved: "Approved",
        on_hold: "On Hold",
        declined: "Declined",
        completed: "Completed",
        approve: "Approve",
        decline: "Decline",
      },
      priority: {
        none: "None",
        low: "Low",
        medium: "Medium",
        high: "High",
        critical: "Critical",
      },
      columns: {
        title: "Title",
        id: "ID",
        description: "Description",
        status: "Status",
        priority: "Priority",
        location: "Location",
        asset: "Asset",
        createdAt: "Created when",
        createdBy: "Created by",
        updatedAt: "Updated when",
        updatedBy: "Updated by",
        workOrder: "Work Order",
        categories: "Category",
        vendors: "Vendor",
        neededBy: "Needed by",
        assignedTo: "Assigned to",
        comments: "Comments",
        internalRequest: "Internal",
        requestPortalId: "Request Source",
        completedAt: "Completed when",
      },
      deletionConfirmation: {
        title: "Delete request?",
        description: "You're about to permanently delete the request",
        confirm: "Delete request",
      },
    },

    exportQRCodes: {
      hint: "QR Codes",
      confirmation: {
        title: "Print QR Codes for Selected {{module}}",
        selected: "{{count}} {{module}} Selected",
        exportSetting: "Export setting",
        cancelButtonLabel: "Cancel",
        confirmButtonLabel: "Print",
        singleOption: "One QR Сode per page",
        multipleOption: "Multiple QR Codes per A4 page",
      },
      validationConfirmation: {
        title: "Generate QR codes?",
        description:
          "{{names}} don’t have the QR codes. You can generate QR codes for these records or print out only the existing ones",
        secondaryButton: "No, Print existing",
        primaryButton: "Yes, Generate",
      },
    },

    printWorkOrders: {
      exportPdf: "Export as PDF",
      confirmation: {
        singleTitle: "Print Work Order",
        bulkTitle: "Print Selected Work Orders",
        selected: "{{count}} Work Orders Selected",
        exportSetting: "Export setting",
        cancelButtonLabel: "Cancel",
        confirmButtonLabel: "Print",
      },
    },

    printPMs: {
      exportPdf: "Export as PDF",
      confirmation: {
        singleTitle: "Print PMs",
        bulkTitle: "Print Selected PMs",
        selected: "{{count}} PMs Selected",
        exportSetting: "Export setting",
        cancelButtonLabel: "Cancel",
        confirmButtonLabel: "Print",
      },
    },

    vendors: {
      title: "Vendors",
      searchPlaceholder: "Search vendors",
      title_one: "Vendor",
      title_other: "Vendors",
      noVendors: "No Vendors Created Yet",
      createNew: "New Vendor",
      edit: "Edit Vendor #{{id}}",
      changeStatus: "Make {{newStatus}}",
      name: "Vendor | {{name}}",

      bulkActions: {
        delete: {
          confirmation: {
            title: "Delete vendors?",
            description_one:
              "You're about to permanently delete {{count}} vendor",
            description_other:
              "You're about to permanently delete {{count}} vendors",
            confirmButtonLabel_one: "Delete vendor",
            confirmButtonLabel_other: "Delete vendors",
          },
          success: "{{count}} vendors were successfully deleted",
          warningTitle: "Some of the vendors cannot be deleted",
          warningContent:
            "Vendors {{names}} are linked with at least one work order or request. Delete them first, then delete the vendors",
        },
      },

      columns: {
        name: "Name",
        rating: "Rating",
        id: "ID",
        description: "Description",
        taxId: "Tax ID",
        vendorContacts: "Contacts",
        urls: "URL",
        assignToWorkOrders: "Assign to WO",
        partsSupplier: "Parts Supplier",
        categories: "Category",
        parts: "Parts",
        hourlyRate: "Hourly Rate",
        locations: "Location",
        assets: "Assets",
        address: "Address",
        createdBy: "Created by",
        createdWhen: "Created when",
        updatedBy: "Updated by",
        updatedWhen: "Updated when",
      },
      fields: {
        labels: {
          name: "Name",
          description: "Description",
          tax_id: "Tax ID",
          urls_attributes: "URL",
          hourly_rate: "Hourly Rate",
          address: "Address",
          locations_ids: "Location",
          assign_to_work_orders: "Assign to Work Orders",
          parts_supplier: "Parts Supplier",
          categories_ids: "Category",
          assets_ids: "Assets",
          parts_ids: "Parts",
          rating: "Rating",
          vendor_contacts_attributes: "Contacts",
        },
        placeholders: {
          name: "Vendor Name",
          description: "Description",
          tax_id: "Tax ID",
          hourly_rate: "Hourly Rate",
          address: "City, State, Zip, Country",
          startTyping: "Start typing",
        },
        tooltip:
          "Helps identify and merge data of all business entities that work with the same vendors to build precise internal reports.",
      },
      snack: {
        successCreationTitle: "Vendor created",
        successCreationMessage: "{{name}} was successfully created",
        view: "View vendor",
        failCreationTitle: "Failed to create vendor",
        failedUpdate: "Failed to update vendor",
      },
      details: {
        hourly_rate: "Hourly Rate",
        address: "Address",
        locations: "Location",
        tax_id: "Tax ID",
        assign_to_work_orders: "Assign to Work Orders",
        parts_supplier: "Parts Supplier",
        categories: "Category",
        assets: "Assets",
        parts: "Parts",
        rating: "Rating",
        urls: "URL",
      },
      deletionConfirmation: {
        title: "Delete vendor?",
        description: "You're about to permanently delete the vendor",
        confirm: "Delete vendor",
      },
      warningDeletionTitle: "Vendor cannot be deleted",
      warningDeletionContent:
        "The vendor is linked with at least one work order or request. Delete them first, then delete the vendor",
      updateStatusConfirmation: {
        inactive: {
          title: "Make vendor inactive?",
          description:
            "We'll hide the vendor from you. However, you'll be able to access it via table settings",
          confirm: "Make inactive",
        },
        active: {
          title: "Make vendor active?",
          description: "You're about to make the vendor active",
          confirm: "Make active",
        },
        success: "{{name}} was labeled as {{newStatus}}",
        fail: "Failed to label as {{newStatus}}",
      },
    },

    contacts: {
      title: "Contacts",
      add: "Add Contact",
      edit: "Edit Contact",
      copy: "{{email}} copied to your clipboard",
      successCreation: "Contacts updated",
      failedCreation: "Contacts update failed",

      form: {
        name: "Full Name",
        role: "Role",
        email: "Email",
        phone: "Phone",
        extension: "Extension",
        primaryContact: "Primary Contact",
      },
      validation: {
        name: "This contact name already exists",
        email: "Please enter a valid email address",
        extension: {
          number: "Phone number extension can only contain numeric characters",
          phone:
            "A phone number must be provided in order to include an extension",
        },
      },
      snack: {
        successCreationMessage: "Contact was successfully created",
        failCreationTitle: "Failed to create contact",
        failedUpdate: "Failed to update contact",
      },
      delete: {
        confirmation: {
          title: "Delete contact?",
          description: "You're about to permanently delete the contact",
          confirm: "Delete contact",
        },
      },
    },

    work_orders: {
      name: "Work Order | {{name}}",
      title: "Work Orders",
      searchPlaceholder: "Search work orders",
      title_abb: "WO",
      title_one: "Work Order",
      history: "Work Order History",
      noWorkOrders: "No Work Orders Created Yet",
      error: "Could not retrieve work orders",
      detailsError: "Error work order",
      createNew: "New Work Order",
      edit: "Edit Work Order #{{id}}",
      addChecklist: "Add Field",
      calendar: {
        wo: "WO",
        pm: "PM",
        due: "Due on ",
        total: " work orders in total",
      },
      tabs: {
        list: "List",
        calendar: "Calendar",
        comments: "Comments {{count}}",
        history: "History",
      },
      hideWorkOrders: "Hide Completed Work Orders",

      kpis: {
        button: "KPIs",
        overdue: "Overdue",
        unassigned: "Unassigned",
        textItemDesc: "out out {{active}} active",
        backlogByStatus: "Backlog by Status",
        backlogByPriority: "Backlog by Priority",
        reactive: "Reactive",
        preventive: "Preventive",
        error: "No KPIs available",
      },
      columns: {
        title: "Title",
        id: "ID",
        type: "Work Type",
        status: "Status",
        priority: "Priority",
        dueDate: "Due Date",
        assignedTo: "Assigned to",
        categories: "Category",
        location: "Location",
        description: "Description",
        asset: "Asset",
        vendors: "Vendor",
        schedule: "Schedule",
        request: "Request",
        comments: "Comments",
        createdBy: "Created by",
        createdAt: "Created when",
        updatedBy: "Updated by",
        updatedAt: "Updated when",
        completedAt: "Completed when",
        downtime: "Downtime",
      },
      bulkActions: {
        hint: {
          dueDate: "Due Date",
          assignees: "Assignees",
          status: "Status",
          priority: "Priority",
          delete: "Delete",
          print: "Print",
        },
        delete: {
          confirmation: {
            title: "Delete work orders?",
            description_one:
              "You're about to permanently delete {{count}} work order",
            description_other:
              "You're about to permanently delete {{count}} work orders",
            confirmButtonLabel_one: "Delete work order",
            confirmButtonLabel_other: "Delete work orders",
          },
          success: "{{count}} work orders were successfully deleted",
          warningTitle: "Some of the vendors cannot be deleted",
          warningContent:
            "Vendors {{ids}} are linked with at least one work order or request. Delete them first, then delete the vendors",
        },
        updatePriority: {
          confirmation: {
            title: "Update Priority",
            selected_one: "{{count}} Work Order Selected",
            selected_other: "{{count}} Work Orders Selected",
            newPriority: "Priority",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success:
            "The priority of {{count}} work orders was updated to {{status}}",
          fail: "Failed to update priority",
          options: {
            none: "None",
            low: "Low",
            medium: "Medium",
            high: "High",
            critical: "Critical",
          },
        },
        updateAssignees: {
          confirmation: {
            title: "Update Assignees",
            selected_one: "{{count}} Work Order Selected",
            selected_other: "{{count}} Work Orders Selected",
            new: "Assign to",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success:
            "The assignee of {{count}} work orders was updated to {{assignees}}",
          fail: "Failed to update assignees",
        },
        updateStatus: {
          confirmation: {
            title: "Update Status",
            selected_one: "{{count}} Work Order Selected",
            selected_other: "{{count}} Work Orders Selected",
            new: "Status",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success:
            "The status of {{count}} work orders was updated to {{newValue}}",
          fail: "Failed to update status",
          invalidPartQty:
            "The entered quantity of some parts added to the selected work orders {{workOrders}} exceeds the quantity on hand. First update the quantity and then update the status of the work orders.",
          bulkSuccess:
            "The status of other work orders {{workOrders}} was updated to completed",
          options: {
            open: "Open",
            on_hold: "On Hold",
            in_review: "In Review",
            in_progress: "In Progress",
            completed: "Completed",
            waiting_for_parts: "Waiting for Parts",
            waiting_for_cost: "Waiting for Cost",
            waiting_for_invoice: "Waiting for Invoice",
          },
          assetValidation: {
            title: "Update status of assets",
            description:
              "Some of the assets linked to the selected work orders have status “Offline”. Please update the status of these assets if needed to display the data correctly.",
            confirm: "Got it",
          },
        },
        updateDueDate: {
          confirmation: {
            title: "Update Due Date",
            selected_one: "{{count}} Work Order Selected",
            selected_other: "{{count}} Work Orders Selected",
            new: "Due Date",
            cancelButtonLabel: "Discard Changes",
            confirmButtonLabel: "Save",
          },
          success:
            "The due date of {{count}} work orders was updated to {{date}}",
          fail: "Failed to update due date",
        },
      },
      priority: {
        none: "None",
        low: "Low",
        medium: "Medium",
        high: "High",
        critical: "Critical",
        none_pm: "None",
        low_pm: "Low",
        medium_pm: "Medium",
        high_pm: "High",
        critical_pm: "Critical",
      },
      status: {
        in_progress: "In Progress",
        open: "Open",
        on_hold: "On Hold",
        completed: "Completed",
        in_review: "In Review",
        waiting_for_cost: "Waiting for cost",
        waiting_for_invoice: "Waiting for invoice",
        waiting_for_parts: "Waiting for parts",
        planned: "Planned",
        paused: "Paused",
        completedCalendar: "Completed",
      },
      type: {
        reactive: "Reactive",
        preventive: "Preventive",
      },
      schedule_type: {
        one_time: "One-time",
        persistent: "Persistent",
        floating: "Floating",
      },
      details: {
        description: "Description",
        priority: "Priority",
        due_date: "Due Date",
        type: "Work Type",
        categories: "Category",
        asset: "Asset",
        location: "Location",
        assign_to: "Assigned to",
        created_by: "Created",
        request: "Request",
        request_num: "Request #{{id}}",
        requested_by: "Requested by",
        vendors: "Vendor",
        schedule: "Schedule",
        checklist: "Checklist",
        downtime_tracking: "Downtime",
      },
      deletionConfirmation: {
        title: "Delete work order?",
        description: "You're about to permanently delete the work order",
        confirm: "Delete work order",
      },
      assetValidation: {
        title: "Linked Asset is Offline",
        description:
          "The Asset linked to this work order has status “Offline”. Would you like to leave it as is, set its status to online now, or set its status to online some time in the past?",
        buttons: {
          leaveOffline: "Leave offline",
          onlineNow: "Online: Now",
          onlineSelectDate: "Online: Select date & time",
        },
      },
      comments: {
        title: "Comment",
        send: "Send",
        edit: "Edit Comment",
        edited: "(edited)",
        placeholder: "Write a comment",
        successCreationMessage: "Comment was successfully created",
        failCreationTitle: "Failed to create comment",
        failedUpdate: "Failed to update comment",

        deletionConfirmation: {
          title: "Delete comment?",
          description: "You're about to permanently delete the comment",
          confirm: "Delete comment",
        },
      },
      snack: {
        successUpdateMessage: "{{name}} was successfully updated",
        successRescheduleMessage: "{{name}} was successfully rescheduled",
        failRescheduleMessage: "Failed to reschedule work order",
        failUpdateTitle: "Failed to update work order",
        view: "View {{module}}",
        successCreationTitle: "{{module}} created",
        successCreationMessage: "{{name}} was successfully created",
        failCreationTitle: "Failed to create work order",
        invalidPartQty:
          "The entered quantity of {{parts}} exceeds the quantity on hand. Please update the quantity first and then update the status of work order",
      },
      form: {
        title: "Title",
        description: "Description",
        notify: "Receive Notifications on Updates",
        priority: "Priority",
        location: "Location",
        startTyping: "Start typing",
        assets: "Asset",
        category: "Category",
        due_date: "Due Date",
        datePlaceholder: "mm/dd/yyyy",
        vendor: "Vendor",
        assign_to: "Assign to",
        type: "Work Type",
        checklist: "Checklist",
        status: "Status",
        pm_schedule_attributes: {
          repeat_options: {
            hour: "Hour",
            day: "Day",
            week: "Week",
            month: "Month",
            year: "Year",
          },
          week_days: {
            sun: "Sun",
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
          },
          full_week_days: {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday",
          },
          months: {
            jan: "Jan",
            feb: "Feb",
            mar: "Mar",
            apr: "Apr",
            may: "May",
            jun: "Jun",
            jul: "Jul",
            aug: "Aug",
            sep: "Sep",
            oct: "Oct",
            nov: "Nov",
            dec: "Dec",
          },
          full_months: {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
          },
          dayOfMonth: {
            1: "1st",
            2: "2nd",
            3: "3rd",
            21: "21st",
            22: "22nd",
            23: "23rd",
            31: "31st",
          },
          lastDay: "{{value}} (or last)",
          on_month_day: "On the",
          repeats_every: "Repeats every",
          repeat_on: "Repeat on",
          start_date: "Starting from",
          time_to_complete: "Time to complete",
          schedule: "Schedule",
          information: {
            creation: "First work order will be created on {{startDate}}.",
            work_order: "Work order will be created on {{value}}.",
            work_order_detail: "Work order is created on {{value}}.",
            due_date: "Due Date is in {{value}} after work order creation.",
            repeats_text: {
              floating:
                "Repeats every {{occurence}} {{frequency}} after the completion of the previous work order.",
              week: `Repeats every {{occurence}} {{frequency}} on {{weekNames}}.`,
              month_custom:
                "Repeats every {{monthNames}} on the {{monthDay}} day.",
              month:
                "Repeats every {{occurence}} {{frequency}} on the {{monthDay}} day.",
              year: "Repeats every {{occurence}} {{frequency}}.",
              lastDay: "{{value}} or last",
            },
          },
        },
        costAndTime: "Cost and Time",
        setToOffline: "Set Asset Status to “Offline” (Beta)",
        typeTooltip:
          "Reactive work orders are not planned or reoccurring. Preventive work orders are planned and usually reoccur on a schedule.",
      },
      historyAttributes: {
        amount_in_minutes: "Minutes",
        asset: "Asset",
        category: "Category",
        checklist: "Checklist",
        checklist_progress: "Checklist progress",
        completed_at: "Completed at",
        cost: "Cost",
        create: "Created work order",
        created_by: "Created by",
        description: "Description",
        due_date: "Due date",
        location: "Location",
        note: "Note",
        priority: "Priority",
        rate: "Rate",
        rate_type: "Rate type",
        started_at: "Started at",
        team: "Assigned to",
        title: "Title",
        total_time_cost: "Total time cost",
        updated_by: "Updated by",
        user: "Assigned to",
        vendor: "Vendor",
        work_order_status: "Status",
      },
      totals: {
        parts: "Parts Cost",
        labor: "Labor Cost",
        other: "Other Costs",
        total: "Total Cost",
      },
    },

    preventive_maintenances: {
      name: "PM | {{name}}",
      title: "Preventive Maintenance",
      searchPlaceholder: "Search PMs",
      noPMs: "No PMs Created Yet",
      error: "Could not retrieve preventive maintenances",
      detailsError: "Error preventive maintenance",
      active: "Active",
      edit: "Edit PM #{{id}}",
      activate: "Activate Now",

      columns: {
        title: "Title",
        status: "Status",
        coming_up: "Coming up",
        progression: "Progression",
        next_date: "Next Date",
        priority: "Priority",
        assignedTo: "Assigned to",
        categories: "Category",
        asset: "Asset",
        vendors: "Vendor",
        location: "Location",
        id: "ID",
        description: "Description",
        start_date: "Starting from",
        schedule: "Schedule",
        time_to_complete: "Time to complete",
        createdBy: "Created by",
        createdAt: "Created when",
        updatedBy: "Updated by",
        updatedAt: "Updated when",
      },
      status: {
        planned: "Planned",
        paused: "Paused",
        completed: "Completed",
      },
      time_to_complete: {
        hour: "{{value}} hour",
        hours: "{{value}} hours",
        days: "{{value}} days",
      },
      bulkActions: {
        updatePriority: {
          confirmation: {
            selected_one: "{{count}} PM Selected",
            selected_other: "{{count}} PMs Selected",
          },
          success: "The priority of {{count}} PMs was updated to {{status}}",
        },
        updateAssignees: {
          confirmation: {
            selected_one: "{{count}} PM Selected",
            selected_other: "{{count}} PMs Selected",
          },
          success: "The assignee of {{count}} PMs was updated to {{assignees}}",
        },
        updateStatus: {
          confirmation: {
            selected_one: "{{count}} {{status}} PM Selected",
            selected_many: "{{count}} {{status}} PMs Selected",
            title: {
              paused: "Pause PMs",
              planned: "Resume PMs",
            },
            confirmButtonLabel: {
              paused: "Pause PMs",
              planned: "Resume PMs",
            },
          },
          fail: "Failed to update status",
          validationError: {
            paused:
              "None of the selected PMs is “Planned”. You can pause only “Planned” PMs. Choose them and repeat the action",
            planned:
              "None of the selected PMs is “Paused”. You can resume only “Paused” PMs. Choose them and repeat the action",
          },
        },
        hint: {
          paused: "Pause",
          planned: "Resume",
        },
      },
      details: {
        start_date: "Starting from",
        progression: "Progression",
        time_to_complete: "Time to complete",
        schedule: "Schedule",
      },
      clone: {
        action: "Clone this PM",
        header_label: "Title: ",
        title: "Clone PM",
        successTitle: "PM cloned",
        successMessage: "PM was successfully cloned",
        assetPrompt: "Choose an asset to clone this PM to:",
        snackBar: {
          success: {
            title: "Successfully cloned PM",
            content: "This PM has been cloned to the selected asset.",
            primaryButtonLabel: "",
          },
          error: {
            title: "PM clone error",
          },
        },
      },
      deletion: {
        confirmation: {
          title: "Delete PM?",
          description: "You're about to permanently delete the PM.",
          confirm: "Delete PM",
        },
        error: {
          title: "PM cannot be deleted",
          content:
            "The PM is linked with at least one work order. Delete them first, then delete the PM.",
        },
      },
      snack: {
        failUpdateTitle: "PM was not updated",
      },

      workOrderHistory: {
        chart: {
          createdCount: "Created Work Orders",
          completedCount: "Completed Work Orders",
        },
      },
    },

    auth: {
      snack: {
        loginTitle: "Login",
        signUpTitle: "Sign Up",
        successCreationMessage: "User was successfully created",
        failCreationTitle: "Failed to create user",
        successLoginMessage: "You have successfully logged in",
        failLoginTitle: "Login failed",
      },
      login: {
        title: "Sign In",
        forgotPassword: "Forgot Password?",
        login: "Sign In",
        noAccount: "Don't have an account?",
        signUp: "Sign Up for a Free Trial",
        emailLabel: "Email",
        emailPlaceholder: "Email",
        passwordLabel: "Password",
        passwordPlaceholder: "Password",
        invalidEmailOrPassword: "Invalid email or password",
        loginLimit: "You’ve reached the 5 login attempt limit",
        loginLimitDescription:
          "You have reached the maximum number of attempts. Please try again in 20 minutes.",
      },

      resetPassword: {
        title: "Reset Password",
        email: "Email",
        emailPlaceholder: "Email",
        error: "Password reset failed",
        resetPasswordButton: "Reset Password",
        passwordReset: "Password Reset",
        emailSentDescription:
          "We have sent you an email. Follow the instructions to reset your password.",
        newPassword: "New Password",
        updatePasswordButton: "Update Password",
        password: "Password",
        resetComplete: "Reset Complete",
        backToSignInButton: "Back to Sign In",
        passwordExpiredTitle: "Password change link has expired",
        passwordExpiredDescription:
          "If you still need to reset your password, you can request a new reset email.",
        resendPasswordButton: "Resend Password Reset link",
      },

      signUp: {
        title: "Sign Up",
        signUp: "Create Account",
        haveAccount: "Already have an account?",
        login: "Sign In",
        emailLabel: "Email",
        emailPlaceholder: "Email",
        passwordLabel: "Password",
        passwordPlaceholder: "Password",
        createPasswordLabel: "Create Password",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "First Name",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "Last Name",
        companyLabel: "Company",
        companyPlaceholder: "Company",
        phoneNumberLabel: "Phone",
        phoneNumberPlaceholder: "Phone",
        acceptCommunication:
          "I would like to receive additional communication about Click Maint's services",
        newUser: "New User",
        invitationExpiredTitle:
          "Your invitation has expired or is no longer valid",
        invitationExpiredDescription:
          "To request another one, contact your system Admin to send you a new invitation",
        ok: "OK",
        passwordContains:
          "Contains at least one uppercase letter, one lowercase letter, one number, and one special character (e.g., !, @, #, $, %)",
        passwordLength: "At least 8 characters",
        passwordStrength: "Password Strength: ",
        verifyEmail: "Verify your Email Address",
        verifyEmailDescription:
          "A verification email has been sent to your email address. Please check your inbox and click the link to start using your Click Maint account.",
        verifyEmailResendButton: "Resend Confirmation Email",
        verificationEmailExpired: "Email verification link has expired",
        verificationEmailExpiredDescription:
          "Please sign in to get the new link.",
        verificationEmailExpiredButton: "Resend Verification link",
      },
      logout: "Sign out",
    },
    profile: {
      title: "My Account",
      change: "Change",
      sessions: "Sessions",
      logoutFromDevice: "Log Out from this Device",
      editPersonalInfo: "Edit Personal Info",
      unknownDevice: "Unknown Device",

      email: {
        title: "Email",
        modalTitle: "Change Email",
        resendVerificationEmail: "Resend Verification Email",
        sendError:
          "An error occurred while attempting to send the email to verify your email address. Please try again later",
        confirmation: {
          title: "Verify your new email address",
          description:
            "A verification email has been sent to your new email address. Please check your inbox and click the link to complete the process.",
          confirm: "Got it ",
        },
      },

      phoneNumber: {
        title: "Phone Number",
        modalTitle: "Change Phone Number",
        successUpdated: "Phone number was successfully updated",
      },

      personalInfo: {
        modalTitle: "Personal Info",
        name: "First Name",
        surname: "Last Name",
        uploadPicture: "Upload New Picture",
        successUpdate: "Profile info was successfully updated",
      },

      password: {
        title: "Password",
        modalTitle: "Change Password",
        oldPassword: "Old Password",
        newPassword: "New Password",
        successUpdate: "Password was successfully updated",
      },

      leaveOrganization: {
        title: "Leave Organization",
        description:
          "If you leave you will lose access to all your Work Orders and you will be required to receive a new invitation to rejoin.",
        confirmation: {
          title: "You are about to leave the organization permanently",
          description:
            "If you want to join again, you'll have to be invited again.",
          confirm: "Leave Organization",
        },
      },

      tabs: {
        profilePreference: "Profile Preferences",
        notificationSettings: "Notification Settings",
        getMobileApp: "Get the Mobile App",
      },
    },
    work_order_time: {
      title: "Time",
      add: "Add Time",
      edit: "Edit Time",
      start: "Start Timer",
      stop: "Stop Timer",
      confirm: "Confirm Time",

      form: {
        amount: "Amount",
        rate_type: "Rate Type",
        user_id: "User",
        placeholder: {
          amount: "hh:mm",
        },
      },
      rate_type: {
        regular: "Regular",
        overtime_1: "Overtime 1",
        overtime_2: "Overtime 2",
      },
      snack: {
        failCreationTitle: "Failed to add work order time",
        failUpdationTitle: "Failed to update work order time",
        failDeletionTitle: "Failed to delete work order time",
        failStartTitle: "Failed to start work order timer",
        failStopTitle: "Failed to stop work order timer",
      },
      delete: {
        confirmation: {
          title: "Delete worked hours from the work order?",
          description:
            "You're about to permanently delete worked hours from the work order",
          confirm: "Delete worked hours",
        },
      },
      overdueTimerConfirmation: {
        title: "Prolonged timer usage",
        description:
          "You've been actively using the timer for an extended period of time. Would you like to stop the timer?",
        confirm: "Stop timer",
      },
    },
    work_order_parts: {
      title: "Parts",
      add: "Add Part",
      edit: "Edit Part",

      form: {
        part_id: "Part",
        quantity: "Quantity",
        noOptions: "No Connected Parts",
      },
      snack: {
        failCreationTitle: "Failed to add work order part",
        failUpdationTitle: "Failed to update work order part",
        failDeletionTitle: "Failed to delete work order part",
      },
      validation: {
        quantity: "The entered quantity exceeds the quantity on hand",
      },
      delete: {
        confirmation: {
          title: "Delete part from the {{module}}?",
          description:
            "You're about to permanently delete the part from the {{module}}",
          confirm: "Delete part",
        },
      },
    },
    work_order_other_costs: {
      title: "Other Costs",
      add: "Add Cost",
      edit: "Edit Cost",

      form: {
        title: "Title",
        note: "Notes",
        vendor: "Vendor",
        cost: "Cost",
      },
      snack: {
        failCreationTitle: "Failed to add work order other cost",
        failUpdationTitle: "Failed to update work order other cost",
        failDeletionTitle: "Failed to delete work order other cost",
      },
      delete: {
        confirmation: {
          title: "Delete cost from the work order?",
          description:
            "You're about to permanently delete cost from the work order",
          confirm: "Delete cost",
        },
      },
    },

    reporting: {
      title: "Reports & KPIs",
      report: "Report",
      selectPlaceholder: "Select a report",
      noData: "Not Enough Data",
      total: "Total",
      kpis: "KPIs",
      noDataHeader: "No Reports to Show",
      noDataDetail: "Not enough data yet. Continue to use Click Maint.",
      mttr_label: "MTTR",
      mttr: "{{mttr, number}}h",
      periodSelect: {
        name: "Date Period",
        placeholder: "Select report period",
      },
      periodOptions: {
        thisWeek: "This Week",
        thisMonth: "This Month",
        thisQuarter: "This Quarter",
        thisYear: "This Year",
        prevWeek: "Last Week",
        prevMonth: "Last Month",
        prevQuarter: "Last Quarter",
        prevYear: "Last Year",
        "7d": "Last 7 Days",
        "30d": "Last 30 Days",
        "90d": "Last 90 Days",
        all: "All Time",
        custom: "Custom",
        current: "Current",
      },
      exportMenu: {
        xls: "Export as XLS file",
        pdf: "Export as PDF file",
        pdfLoading: "Waiting for data...",
        pdfExporting: "Exporting PDF...",
        error: "Export failed",
      },
      bucketOptions: {
        day: "Day",
        dailyView: "Daily View",
        day_short: "D",
        week: "Week",
        weeklyView: "Weekly View",
        week_short: "W",
        month: "Month",
        monthlyView: "Monthly View",
        month_short: "M",
        year: "Year",
        yearlyView: "Yearly View",
        year_short: "Y",
      },
      parts_inventory: {
        title: "Parts Usage",
        paginationLabel: "Parts",
        kpis: {
          created: "Created",
        },
        columns: {
          part_name: "Part Name",
          part_type: "Part Type",
          cost: "Cost",
          quantity: "Quantity",
          date: "Date",
        },
        chart: {
          part_types: "Part Types",
          parts: "Parts",
          labels: {
            amount: "Amount",
            cost: "Cost",
          },
        },
        labels: {
          cost: "Cost",
          quantity: "Quantity",
          spent: "Spent on {{value}}",
        },
        widget_kpis: {
          costs: "Costs this period: {{value}}",
        },
      },
      wo_by_assignee: {
        title: "WO by Assignee",
        active: "{{count}} Active",
        overdue: "{{count}} Overdue",
        paginationLabel: "Assignees",
        kpis: {
          active: "Active",
          overdue: "Overdue",
          not_assigned: "Not Assigned",
          reactive: "Reactive",
          preventive: "Preventive",
          avg_age: "Avg. Age (Days)",
        },
        labels: {
          active: "Active",
          overdue: "Overdue",
          total: "Total",
          pending: "Pending",
        },
        columns: {
          assignee: "Assignee",
          active: "Active",
          overdue: "Overdue",
          on_time: "On Time",
          reactive: "Reactive",
          preventive: "Preventive",
        },
      },
      completed_by_category: {
        columns: {
          category: "Category",
          completed: "Completed",
          avg_time_to_complete: "Avg. Hours to Complete",
          avg_cost: "Avg. Cost",
          reactive: "Reactive (%)",
          preventive: "Preventive (%)",
          cost: "Cost",
          on_time: "On Time",
          mttr: "MTTR",
          count: "Count",
          value_with_percentage: "{{value}} ({{percentage}})",
        },
        kpis: {
          created: "Created",
          completed: "Completed",
          completed_percentage: "Completed %",
          on_time: "On Time",
          on_time_percentage: "On Time %",
          mttr: "MTTR",
          top_expenses: "{{category}}",
          reactive: "Reactive",
          preventive: "Preventive",
          reactive_percent: "Reactive %",
          preventive_percent: "Preventive %",
          avg_ttc: "Avg. Hours To Complete",
          total_cost: "Total Cost",
        },
        labels: {
          carpentry: "Carpentry",
          cleaning: "Cleaning",
          damage: "Damage",
          electrical: "Electrical",
          fleet: "Fleet",
          groundskeeping: "Groundskeeping",
          hvac: "HVAC",
          inspection: "Inspection",
          inspections: "Inspections",
          mechanical: "Mechanical",
          other: "Other",
          painting: "Painting",
          plumbing: "Plumbing",
          project: "Project",
          refrigeration: "Refrigeration",
          roofing: "Roofing",
          safety: "Safety",
          security: "Security",
          standard_operating_procedure: "Standard Operating Procedure",
          "standard operating procedure": "Standard Operating Procedure",
          uncategorized: "Uncategorized",
        },
        detailKpis: "Top {{count}} Expenses",
        paginationLabel: "Categories",
        title: "WO Completed by Category",
      },
      history_by_assignee: {
        title: "Maintenance by Assignee",
        paginationLabel: "Assignees",
        kpis: {
          total_completed: "Total WOs",
          on_time_percentage: "On Time",
          overdue_percentage: "Overdue",
          total_labor_hours: "Hours",
          regular_labor_hours: "Regular Hours",
          overtime_labor_hours: "OT Hours",
          regular_labor_hours_percentage: "Regular Labor Hours",
          overtime_labor_hours_percentage: "Overtime Labor Hours",
          avg_time_to_complete: "Avg. Hrs to Completed",
        },
        widget_kpis: {
          total_completed: "{{value}} Total WOs",
          on_time_percentage: "{{value}} On Time",
          overdue_percentage: "{{value}} Overdue",
          total_labor_hours: "{{value}} Hours",
          regular_labor_hours: "{{value}} Regular Hours",
          overtime_labor_hours: "{{value}} OT Hours",
          regular_labor_hours_percentage: "{{value}} Regular Labor Hours",
          overtime_labor_hours_percentage: "{{value}} Overtime Labor Hours",
          avg_time_to_complete: "{{value}} Avg. Hrs to Completed",
        },
        columns: {
          assignee: "Assignee",
          total_labor_hours: "Total Labor Hours",
          total_regular_labor_hours: "Total Regular Hours",
          total_overtime_hours: "Total Overtime Hours",
          completed_work_orders: "Completed Work Orders",
        },
        chart: {
          total_labor_hours: "Total Labor Hours",
          total_regular_labor_hours: "Total Regular Hours",
          total_overtime_hours: "Total Overtime Hours",
          completed_work_orders: "Completed Work Orders",
        },
        labels: {
          total_labor_hours: "Total Labor Hours",
          total_overtime_hours: "Total Overtime Hours",
          total_regular_labor_hours: "Total Regular Hours",
        },
      },
      backlog_by_priority: {
        title: "Maintenance Backlog by Priority",
        labels: {
          none: "None",
          low: "Low",
          medium: "Medium",
          high: "High",
          critical: "Critical",
        },
        kpis: {
          avg_age: "AVG Age",
          overdue_count: "Overdue",
          preventive_percentage: "Preventive",
          reactive_percentage: "Reactive",
          total_work_orders: "Total",
        },
        columns: {
          priority: "Title",
          amount: "Amount",
          amount_percentage: "Amount %",
          overdue: "Overdue",
          avg_age: "AVG Age",
          reactive: "Reactive",
          preventive: "Preventive",
        },
      },
      backlog_by_status: {
        title: "Maintenance Backlog by Status",
        labels: {
          open: "Open",
          on_hold: "On Hold",
          in_progress: "In Progress",
          in_review: "In Review",
          waiting_for_parts: "Waiting for Parts",
          waiting_for_cost: "Waiting for Cost",
          waiting_for_invoice: "Waiting for Invoice",
        },
        kpis: {
          avg_age: "AVG Age",
          overdue_count: "Overdue",
          preventive_percentage: "Preventive",
          reactive_percentage: "Reactive",
          total_work_orders: "Total",
        },
        columns: {
          status: "Title",
          amount: "Amount",
          amount_percentage: "Amount %",
          overdue: "Overdue",
          avg_age: "AVG Age",
          reactive: "Reactive",
          preventive: "Preventive",
        },
      },
      history_by_costs: {
        title: "Maintenance History by Cost",
        paginationLabel: "Work Orders",
        labels: {
          parts: "Parts",
          labor: "Labor",
          other: "Other",
        },
        kpis: {
          labor_cost: "Labor Cost",
          avg_labor_cost: "AVG Labor Cost",
          parts_cost: "Parts Cost",
          avg_parts_cost: "AVG Parts Cost",
          other_cost: "Other Costs",
          avg_other_cost: "AVG Other Costs",
          total_cost: "Total Cost",
          avg_total_cost: "AVG Total Cost",
        },
        columns: {
          title: "Title",
          labor_cost: "Labor Cost",
          part_cost: "Parts Cost",
          other_cost: "Other Costs",
          total_cost: "Total Cost",
          id: "ID",
          work_order_type: "Work Type",
          assigned_to: "Assigned To",
          category: "Category",
          location: "Location",
          asset: "Asset",
          vendor: "Vendor",
          created_by: "Created By",
          completed_at: "Completed Date",
        },
      },
      on_time_overdue: {
        title: "WO Completed On-time vs. Overdue",
        paginationLabel: "Assignees",
        labels: {
          on_time: "On Time",
          overdue: "Overdue",
          no_date: "No Due Date",
        },
        kpis: {
          on_time: "On Time",
          overdue: "Overdue",
          no_due_date: "No Due Date",
          on_time_percentage: "On Time",
          completed: "Completed",
        },
        columns: {
          assignee: "Assignee",
          on_time: "On Time",
          no_due_date: "No Due Date",
          overdue: "Overdue",
          reactive: "Reactive",
          preventive: "Preventive",
        },
      },
      reactive_preventive: {
        title: "WO Reactive vs. Preventive",
        paginationLabel: "Work Orders",
        labels: {
          reactive: "Reactive",
          preventive: "Preventive",
        },
        kpis: {
          reactive: "Reactive",
          preventive: "Preventive",
          pms_created: "Preventive Created",
          completed: "Completed",
          on_time_percentage: "On Time",
        },
        columns: {
          title: "Title",
          amount: "Amount",
          overdue: "Overdue",
          mttr: "MTTR",
          on_time: "On Time",
          avg_costs: "AVG Cost",
          total_costs: "Total Cost",
        },
      },
      created_completed: {
        title: "WO Completed vs. Created",
        paginationLabel: "Work Orders",
        widget: {
          completed: "Completed",
          includesPrevious:
            "Includes Work Orders created in the previous period",
        },
        labels: {
          created: "Created",
          completed: "Completed",
        },
        columns: {
          title: "Title",
          status: "Status",
          created_at: "Created when",
          work_order_type: "Work Type",
          assigned_to: "Assigned To",
          category: "Category",
          location: "Location",
          asset: "Asset",
          vendor: "Vendor",
          created_by: "Created By",
          completed_at: "Completed Date",
        },
        kpis: {
          created: "Created",
          completed: "Completed",
          completed_percentage: "Completed",
          completed_on_time_percentage: "Completed On Time",
        },
      },
      asset_costs_downtime: {
        title: "Asset Costs & Downtime",
        widget: {
          name: "Top 10 Highest Costing Assets",
          online: "Online %",
          cost: "Cost",
        },
        kpis: {
          total_cost: "Total Cost",
          total_downtime: "Total Downtime",
          planned_downtime: "Planned Downtime",
          unplanned_downtime: "Unplanned Downtime",
          mtbf: "MTBF",
        },
        columns: {
          online: "Online",
          online_pct: "Online %",
          offline: "Offline",
          offline_pct: "Offline %",
          cost: "Maintenance Cost",
        },
        labels: {
          downtime_pct: "Percent Downtime",
          cost: "Asset Cost",
        },
        buttons: {
          downtime: "Downtime",
          cost: "Cost",
          label: "Chart metric",
        },
        paginationLabel: "Assets",
      },
    },

    request_portals: {
      title: "Request Portals",
      addButton: "Request Portal",
      copy: "Copy link",
      error: "Could not retrieve request portals",
      empty: "No Portals Created Yet",
      createNew: "New Request Portal",
      edit: "Edit Request Portal",
      name: "Request Portal",
      visitButton: "Visit Portal",
      copyLink: "Link copied",
      scanQrCode: "Scan QR code to submit a request",
      downloadQrCode: "Download QR Code",
      detailsError: "Error request portal",

      columns: {
        title: "Title",
        description: "Description",
        entity: "Entity",
        link: "Link",
        createdAt: "Created",
        allowAnonymous: "Allow Anonymous Requesters",
      },

      form: {
        title: "Title",
        description: "Description",
        entity: "Entity",
        welcome_text: "Welcome Text",
        instructions: "Instructions",
        allowAnonymous: "Allow Anonymous Requesters",

        default: {
          welcome_text:
            "Please take a moment to fill out the request form with as much detail as possible. This will help us address your needs swiftly and efficiently.",
        },
      },

      labels: {
        welcome_text: "Welcome Text",
        instructions: "Instructions",
        entity: "Entity",
        link: "Link",
        allowAnonymous: "Allow Anonymous Requesters",
      },

      snack: {
        successCreationTitle: "Request portal created",
        successCreationMessage: "{{name}} was successfully created",
        view: "View portal",
        failCreationTitle: "Failed to add request portal",
        failUpdationTitle: "Failed to update request portal",
      },

      allow_anonymous: {
        yes: "Yes",
        no: "No",
      },
    },
    portal: {
      detailsError: "Error portal",
      instructions: "Instructions:",
      poweredBy: "Powered by",
      details: "Request Details",
      contacts: "Contact Information",
      contactsOptional: " (Optional)",
      submit: "Submit Request",
      successfulCreate: "Request Sent Successfully",
      add: "Add Another Request",

      form: {
        title: "Title",
        description: "Description",
        priority: "Priority",
        location: "Location",
        startTyping: "Start typing",
        assets: "Asset",
        category: "Category",
        due_date: "Needed by",
        datePlaceholder: "mm/dd/yyyy",
        vendor: "Vendor",
        assign_to: "Assign to",
        portal_user_name: "First Name",
        portal_user_surname: "Last Name",
        portal_user_email: "Email",
        portal_user_phone_number: "Phone",
        notify: "Receive Notifications on Updates",
        noCategories: "No Categories Found",
        placeholder: {
          title: "Short description",
          description: "Provide more details about the issue",
        },
        depended: {
          locations: {
            selected: "Selected Location",
            asset: "Location Connected With Asset",
            other: "Other Locations",
            vendors: "Locations Connected With Vendors",
            noOptions: "No Connected Locations",
          },
          assets: {
            selected: "Selected Asset",
            other: "Other Assets",
            location: "Assets Connected With Location",
            empty: "Assets Without Location",
            vendors: "Assets Connected With Vendors",
            noOptions: "No Connected Assets",
            noVendors: "Assets Without Vendors",
          },
          vendors: {
            selected: "Selected Vendors",
            asset: "Vendors Connected With Asset",
            location: "Vendors Connected With Location",
            other: "Other Vendors",
            noOptions: "No Connected Vendors",
          },
        },
      },

      snack: {
        failCreationTitle: "Portal Was Not Created",
      },
    },

    fallback: {
      error: "Error",
      description_a: "It looks like something went wrong.",
      description_b: "Our team has been notified – please try again later.",
    },

    notAllowed: {
      title: "Access Denied",
      description: "You do not have permission to view this page.",
      button: "Go back",
    },

    datePicker: {
      nextMonth: "Next Month",
      previousMonth: "Previous Month",
      nextYear: "Next Year",
      previousYear: "Previous Year",
      today: "Today",
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
    },
  },

  errors: {
    m1_one: "The file exceeds the maximum limit of 100 MB",
    m1_other: "On of the files exceeds the maximum limit of 100 MB",
    m2: "This code is already used. The code must be unique across all QR codes in the app.",

    m7: "The maximum number of files was reached. You can’t upload more than {{number}} files.",

    m11: "This asset name already exists",
    m99: "This part type name already exists",
    m100: "This part name already exists",
    m112: "This email already belongs to another user of your organization",
    m113: "User cannot join more than one organization",
    m144: "This entity name is already used. The name must be unique across all the entity names in the app.",
    m153: "Incorrect password",
    m170: "You no longer have access",
    m173: "Your permissions don’t allow you to reschedule Work Orders created by others.",
    m174: "Your permissions don’t allow you to edit Work Orders that are “Completed”",
    m172: "No internet connection",
    m178: "Must be more than “Minimum Qty.",
    m179: "Must be less than “Desired Qty.",

    m44_one: "The file format is invalid",
    m44_other: "One of the files has an invalid format",
  },
};

export default locales;
